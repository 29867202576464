<template>
  <div>
    <slot name="header">
      <h3 v-if="header" class="table-title">
        {{ header }} <i class="fas fa-spin fa-loader" v-show="loading" />
      </h3>
    </slot>

    <div class="buttons-wrapper margin" v-if="showTabs">
      <base-button
        :class="{
          active: selectedStatus == null && selectedCreator == null,
        }"
        @click="changeSelectedStatus(null)"
      >
        <span class="text"> {{ $t("ESTIMATES.ALL") }} </span>
        <span class="number" v-if="!isNaN(counters.all)">
          {{ counters.all }}
        </span>
      </base-button>
      <base-button
        v-if="$currentUserCan($permissions.PERM_APPROVE_ESTIMATIONS)"
        :class="{
          active: isTodoTab,
        }"
        @click="
          changeSelectedStatus([
            ESTIMATE_STATUS_PENDING,
            ESTIMATE_STATUS_REVIEWED,
          ])
        "
        class="DRAFT"
      >
        <span class="text"> {{ $t("COMMON.TODO") }} </span>

        <span class="number" v-if="!isNaN(counters.todo)">
          {{ counters.todo }}
        </span>
      </base-button>
      <base-button
        v-if="$currentUserCan($permissions.PERM_APPROVE_ESTIMATIONS)"
        :class="{
          active: isApproveTab,
        }"
        @click="
          changeSelectedStatus([
            ESTIMATE_STATUS_PENDING_APPROVE,
            ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
          ])
        "
        class="DRAFT"
      >
        <span class="text"> {{ $t("COMMON.PENDING_APPROVE") }} </span>

        <span class="number" v-if="!isNaN(counters.pendingApprove)">
          {{ counters.pendingApprove }}
        </span>
      </base-button>
      <base-button
        v-if="$currentUserCan($permissions.PERM_CREATE_ESTIMATIONS)"
        :class="{
          active: selectedStatus == ESTIMATE_STATUS_DRAFT,
        }"
        @click="changeSelectedStatus(ESTIMATE_STATUS_DRAFT)"
        class="DRAFT"
      >
        <span class="text"> {{ $t("COMMON.DRAFTS") }} </span>

        <span class="number" v-if="!isNaN(counters.draft)">
          {{ counters.draft }}
        </span>
      </base-button>
      <base-button
        v-if="$currentUserCan($permissions.PERM_APPROVE_ESTIMATIONS)"
        :class="{
          active: selectedStatus == ESTIMATE_STATUS_COMPLETED,
        }"
        @click="changeSelectedStatus(ESTIMATE_STATUS_COMPLETED)"
        class="COMPLETED"
      >
        <span class="text">
          {{ $t("ESTIMATES.COMPLETED") }}
        </span>
        <span class="number" v-if="!isNaN(counters.completed)">
          {{ counters.completed }}
        </span>
      </base-button>
      <base-button
        v-if="$currentUserCan($permissions.PERM_CANCEL_ESTIMATIONS)"
        :class="{
          active: selectedStatus == ESTIMATE_STATUS_CANCELED,
        }"
        @click="changeSelectedStatus(ESTIMATE_STATUS_CANCELED)"
        class="CANCELED"
      >
        <span class="text">
          {{ $t("ESTIMATES.CANCELED") }}
        </span>
        <span class="number" v-if="!isNaN(counters.canceled)">
          {{ counters.canceled }}
        </span>
      </base-button>
      <!-- <base-button
        :class="{
          active: selectedStatus == ESTIMATE_STATUS_PENDING,
        }"
        @click="changeSelectedStatus(ESTIMATE_STATUS_PENDING)"
        class="PENDING"
      >
        <span class="text">
          {{ $t("COMMON.PENDING") }}
        </span>
        <span class="number" v-if="!isNaN(counters.pending)">
          {{ counters.pending }}
        </span>
      </base-button> -->
      <!-- <base-button
        :class="{
          active: selectedStatus == ESTIMATE_STATUS_IN_PROGRESS,
        }"
        @click="changeSelectedStatus(ESTIMATE_STATUS_IN_PROGRESS)"
        class="IN_PROGRESS"
      >
        <span class="text">
          {{ $t("COMMON.IN_PROGRESS") }}
        </span>
        <span class="number" v-if="!isNaN(counters.inProgress)">
          {{ counters.inProgress }}
        </span>
      </base-button> -->
      <!-- <base-button
        v-if="$currentUserCan($permissions.PERM_CREATE_ESTIMATIONS)"
        :class="{
          active: selectedCreator == me?.id,
        }"
        @click="changeSelectedStatus(null, me?.id)"
      >
        <span class="text"> {{ $t("ESTIMATES.CREATED_BY_ME") }} </span>
        <span class="number" v-if="!isNaN(counters.createdByMe)">
          {{ counters.createdByMe }}
        </span>
      </base-button> -->

      <i class="fas fa-spin fa-loader" v-if="loading" />
    </div>

    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush estimates"
          header-row-class-name="thead-light has-image"
          :data="estimations"
          @sort-change="sortChange"
          :row-class-name="setDeletedClass"
          @row-click="
            (row) => {
              viewEstimate(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>

          <el-table-column width="75" v-if="canViewColumn('vehicle')">
            <template v-slot="{ row }">
              <div class="image" v-if="row.vehicle">
                <img
                  v-if="row.vehicle?.gallery?.length > 0"
                  :src="row.vehicle?.gallery[0]"
                  class="avatar rounded-circle mr-3"
                />
                <span class="default-icon">
                  <i class="far fa-camera"></i>
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('vehicle')"
            :label="$t('COMMON.VEHICLE')"
            min-width="250"
          >
            <template v-slot="{ row }">
              <div class="vehicle">
                <ul>
                  <li>{{ row?.vehicle?.make }} {{ row?.vehicle?.model }}</li>
                  <li>{{ row?.vehicle?.model_year }}</li>
                </ul>
                <ul>
                  <li v-if="row?.vehicle?.series">
                    {{ row?.vehicle?.series }}
                  </li>
                  <li class="line" v-if="row?.vehicle?.series">|</li>
                  <li v-if="row?.vehicle?.color">{{ row?.vehicle?.color }}</li>
                  <li class="line" v-if="row?.vehicle?.color">|</li>
                  <li>
                    {{ row?.vehicle?.odometer ?? 0 }}
                    {{ row?.vehicle?.odometer_unit }}
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('vin')"
            :label="$t('VEHICLES.SKU') + ' / ' + $t('VEHICLES.VIN')"
            prop="sku"
            min-width="220"
          >
            <template v-slot="{ row }">
              <div class="stock">
                <ul>
                  <li v-if="row.vehicle?.sku">{{ row?.vehicle?.sku }}</li>
                  <li><copy-element :element="row.vehicle?.vin" /></li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('customer')"
            :label="$t('REPAIRS.CLIENT_WORK_ORDER')"
            min-width="200"
            prop="client"
          >
            <template v-slot="{ row }">
              <ul v-if="!!row.customer">
                <li>
                  {{ $objectDenomination(row.customer) }}
                </li>
                <li>{{ row.work_order }}</li>
              </ul>
              <ul v-if="!!row.approver">
                <li>
                  {{ $t("COMMON.INTERNAL") }}
                </li>
                <li>{{ row.work_order }}</li>
              </ul>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('model')"
            :label="$t('COMMON.ESTIMATE')"
            min-width="180"
            prop="code"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <div class="code">
                <ul>
                  <li>{{ $objectDenomination(row) }}</li>
                  <li>{{ row?.items_count }} item(s)</li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('status')"
            :label="$t('COMMON.STATUS')"
            min-width="230"
            prop="status"
          >
            <template v-slot="{ row }">
              <span>
                <estimate-status-badge :status="row.status" :estimate="row" />
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.RESULT')"
            min-width="230"
            prop="status"
          >
            <template v-slot="{ row }">
              <span v-if="getResult(row)">
                <estimate-status-badge :status="getResult(row).status">
                  {{ $t(`COMMON.${getResult(row).value}`) }}
                </estimate-status-badge>
              </span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('approver')"
            :label="$t('ESTIMATES.ASSIGN_APPROB')"
            min-width="150"
          >
            <template v-slot="{ row }">
              <ul>
                <li v-if="!!row.endedApproveBy">
                  {{ $objectDenomination(row.endedApproveBy) }}
                </li>
                <li v-else>{{ $objectDenomination(row.approver) }}</li>
              </ul>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('updated_at')"
            :label="$t('COMMON.UPDATED_AT')"
            min-width="220"
            prop="updated_at"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <span> {{ $timeZoneDateFormat(row.updated_at, "L LT") }} </span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('days')"
            :label="$t('COMMON.DAYS')"
            min-width="150"
          >
            <template v-slot="{ row }">
              <span>{{ row?.days }}</span>
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <template v-slot="{ row }">
              <div class="table-actions" v-if="!isDeleted(row)">
                <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
                  <a
                    type="text"
                    class="table-action view"
                    data-toggle="tooltip"
                    @click="viewEstimate(row)"
                  >
                    <i
                      class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                    ></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  :class="{
                    disabled: !canDeleteEstimate(row),
                  }"
                >
                  <a
                    type="text"
                    class="table-action delete"
                    data-toggle="tooltip"
                    @click="deleteEstimate(row)"
                  >
                    <i class="fa-light fa-trash-can"></i>
                  </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div
      class="list-table-inner-footer"
      v-if="showPagination || total > pagination.perPage"
    >
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import { BasePagination } from "@/components";
import { ESTIMATE_INCLUDES } from "@/constants/estimates";
import CopyElement from "@/components/CopyElement.vue";
import EstimateStatusBadge from "./EstimateStatusBadge.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import { Table, TableColumn, Tooltip } from "element-ui";
import {
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_STATUS_APPROVED,
  ESTIMATE_STATUS_COMPLETED,
  ESTIMATE_STATUS_CANCELED,
  ESTIMATE_STATUS_REVIEWED,
  ESTIMATE_STATUS_DENIED,
  ESTIMATE_STATUS_PENDING,
  ESTIMATE_STATUS_IN_PROGRESS,
  ESTIMATE_STATUS_PENDING_COMPONENTS,
  ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS,
  ESTIMATE_STATUS_PENDING_TIMES,
  ESTIMATE_STATUS_IN_PROGRESS_TIMES,
  ESTIMATE_STATUS_PENDING_APPROVE,
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
  ESTIMATE_TYPES_STATUS_PENDING,
  ESTIMATE_TYPES_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_COMPLETED,
} from "@/constants/estimates";
import estimationsService from "@/store/services/estimations-service";
import trashedMixin from "@/mixins/trashed-mixin";

export default {
  name: "estimate-table",

  components: {
    BasePagination,
    EstimateStatusBadge,
    CopyElement,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [requestErrorMixin, estimatePermissionsMixin, trashedMixin],

  props: {
    header: {
      type: String | Boolean | null | undefined,
      default: null,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    filterOdometer: {
      type: [String, Array, Object],
      default: null,
      description: "Odometer",
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    filterStatus: {
      type: [String, Array],
      default: null,
      description: "Status",
    },
    selectedDateRange: {
      type: Array | String | Date | null,
      default: null,
    },
    excludeStatus: {
      type: String,
      default: null,
    },
    selectedCompletedBy: {
      type: String,
      default: null,
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    selectedVehicleModelYear: {
      type: [String, Number, Array],
      default: null,
    },
    selectedVehicleMake: {
      type: String,
      default: null,
    },
    selectedVehicleModel: {
      type: String,
      default: null,
    },
    selectedVehicleSeries: {
      type: String,
      default: null,
    },
    filterInspection: {
      type: String,
      default: null,
    },
    selectedLocation: {
      type: String,
      default: null,
    },
    filterOrganization: {
      type: String,
      default: null,
    },
    filterCustomer: {
      type: String,
      default: null,
    },
    filterCreator: {
      type: String,
      default: null,
    },
    selectedReviewedBy: {
      type: String,
      default: null,
    },
    query: {
      type: String,
      default: null,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    columns: {
      type: Array | null,
      default: () => [
        "vehicle",
        "vin",
        "customer",
        "model",
        "status",
        "approver",
        "updated_at",
        "days",
      ],
    },
    onlyTrashed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const selectedStatus = this.filterStatus ?? [
      ESTIMATE_STATUS_PENDING,
      ESTIMATE_STATUS_REVIEWED,
    ];
    const selectedCreator = this.filterCreator ?? null;

    return {
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: this.perPage,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      estimations: [],
      loading: false,
      selectedStatus,
      selectedCreator,
      ESTIMATE_STATUS_DRAFT,
      ESTIMATE_STATUS_COMPLETED,
      ESTIMATE_STATUS_PENDING_COMPONENTS,
      ESTIMATE_STATUS_CANCELED,
      ESTIMATE_STATUS_APPROVED,
      ESTIMATE_STATUS_REVIEWED,
      ESTIMATE_STATUS_DENIED,
      ESTIMATE_STATUS_PENDING_TIMES,
      ESTIMATE_STATUS_PENDING_APPROVE,
      ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS,
      ESTIMATE_STATUS_IN_PROGRESS_TIMES,
      ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
      ESTIMATE_STATUS_PENDING,
      ESTIMATE_STATUS_IN_PROGRESS,
      ESTIMATE_TYPES_STATUS_PENDING,
      ESTIMATE_TYPES_STATUS_IN_PROGRESS,
      ESTIMATE_TYPES_STATUS_COMPLETED,
      counters: {
        all: 0,
        draft: 0,
        pending: 0,
        inProgress: 0,
        pendingTime: 0,
        inProgressTime: 0,
        pendingComponent: 0,
        inProgressComponent: 0,
        pendingApprove: 0,
        inProgressApprove: 0,
        canceled: 0,
        completed: 0,
        createdByMe: 0,
      },
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    params() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { search: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: ESTIMATE_INCLUDES.join(","),
      };

      if (this.filterOrganization) {
        params = {
          ...params,
          filter: { ...params.filter, organization: this.filterOrganization },
        };
      }

      if (this.filterOdometer.from) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            odometer_from: this.filterOdometer.from,
          },
        };
      }

      if (this.filterOdometer.to) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            odometer_to: this.filterOdometer.to,
          },
        };
      }

      if (this.selectedLocation) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            allowedLocations: [this.selectedLocation],
          },
        };
      }

      if (this.filterCustomer) {
        params = {
          ...params,
          filter: { ...params.filter, customer: this.filterCustomer },
        };
      }

      if (this.filterInspection) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            inspection: this.filterInspection,
          },
        };
      }

      if (this.selectedStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: this.selectedStatus,
          },
        };
      }

      if (this.selectedDateRange) {
        if (this.selectedDateRange.length == 2) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              created_at: [
                this.selectedDateRange[0] + " 00:00:00",
                this.selectedDateRange[1] + " 23:59:59",
              ],
            },
          };
        }
      }

      if (this.excludeStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            excludeStatus: this.excludeStatus,
          },
        };
      }

      if (this.selectedCreator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: this.selectedCreator,
          },
        };
      }

      if (this.filterVehicle) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: this.filterVehicle,
          },
        };
      }

      if (this.selectedVehicleModelYear) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              model_year: this.selectedVehicleModelYear,
            },
          },
        };
      }

      if (this.selectedVehicleMake) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              make: this.selectedVehicleMake,
            },
          },
        };
      }

      if (this.selectedVehicleModel) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              model: this.selectedVehicleModel,
            },
          },
        };
      }

      if (this.selectedVehicleSeries) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              series: this.selectedVehicleSeries,
            },
          },
        };
      }

      if (this.selectedCompletedBy) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            completedBy: this.selectedCompletedBy,
          },
        };
      }

      if (this.selectedReviewedBy) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            reviewedBy: this.selectedReviewedBy,
          },
        };
      }

      if (this.onlyTrashed) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            only_trashed: true,
          },
        };
      }

      return params;
    },

    isApproveTab() {
      return (
        Array.isArray(this.selectedStatus) &&
        this.selectedStatus.includes(ESTIMATE_STATUS_PENDING_APPROVE) &&
        this.selectedStatus.includes(ESTIMATE_STATUS_IN_PROGRESS_APPROVE)
      );
    },

    isTodoTab() {
      return (
        Array.isArray(this.selectedStatus) &&
        this.selectedStatus.includes(ESTIMATE_STATUS_PENDING) &&
        this.selectedStatus.includes(ESTIMATE_STATUS_REVIEWED)
      );
    },
  },

  async created() {
    await this.getList();

    if (this.showTabs) {
      this.getCounters();
    }
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      // this.estimations = [];
      this.loading = true;

      try {
        let params = this.params;

        await this.$store.dispatch("estimations/list", params);
        this.estimations = this.$store.getters["estimations/list"];
        this.total = this.$store.getters["estimations/listTotal"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async deleteEstimate(estimate) {
      if (!this.canDeleteEstimate(estimate)) {
        return;
      }
      this.$emit("onDeleteEstimate", estimate);
    },

    viewEstimate(estimate) {
      if (this.isDeleted(estimate)) {
        return;
      }

      if (this.canEditEstimate(estimate)) {
        this.$emit("onEditEstimate", estimate);
      }
      if (!this.canViewEstimate(estimate)) {
        return;
      }
      this.$emit("onViewEstimate", estimate);
    },

    editEstimate(estimate) {
      if (!this.canEditEstimate(estimate)) {
        return;
      }
      this.$emit("onEditEstimate", estimate);
    },

    addEstimate() {
      this.$emit("onAddEstimate");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    changeSelectedStatus(status, creator = null) {
      this.selectedStatus = status;
      this.selectedCreator = creator;
    },

    async getCounters() {
      const data = await estimationsService.getCount(this.params);

      const all = Object.values(data).reduce((acc, val) => acc + val, 0);

      const {
        [ESTIMATE_STATUS_DRAFT]: draft = 0,
        [ESTIMATE_STATUS_PENDING]: pending = 0,
        [ESTIMATE_STATUS_REVIEWED]: reviewed = 0,
        [ESTIMATE_STATUS_CANCELED]: canceled = 0,
        [ESTIMATE_STATUS_COMPLETED]: completed = 0,
        [ESTIMATE_STATUS_IN_PROGRESS]: inProgress = 0,
        [ESTIMATE_STATUS_PENDING_APPROVE]: pendingApprove = 0,
        [ESTIMATE_STATUS_IN_PROGRESS_APPROVE]: inProgressApprove = 0,
      } = data;

      this.counters = {
        ...this.counters,
        all,
        draft,
        pending,
        todo: pending + reviewed,
        canceled,
        completed,
        inProgress,
        pendingApprove: pendingApprove + inProgressApprove,
      };
    },

    canViewColumn(column) {
      if (!this.columns || !Array.isArray(this.columns)) return true;

      return this.columns?.includes(column);
    },

    getResult(estimate) {
      if (estimate.status != ESTIMATE_STATUS_COMPLETED) {
        return null;
      }

      if (!estimate.denied_items_count) {
        return { value: "APPROVED", status: "COMPLETED" };
      }

      if (estimate.denied_items_count == estimate.items_count) {
        return { value: "DENIED", status: "CANCELED" };
      }

      return { value: "PARTIALLY_APPROVED", status: "IN_PROGRESS" };
    },
  },

  watch: {
    params: {
      handler: async function (value, oldValue) {
        if (_.isEqual(value, oldValue)) return;

        await this.getList();

        if (this.showTabs && value.filter.status == oldValue.filter.status) {
          this.getCounters();
        }
      },
      immediate: false,
      deep: true,
    },

    filterStatus: function (value) {
      this.selectedStatus = value;
    },

    filterCreator: function (value) {
      this.selectedCreator = value;
    },
    perPage: function (perPage) {
      this.pagination = { ...this.pagination, perPage };
    },
  },
};
</script>

<style lang="scss" scoped></style>
