<template>
  <span class="object-link">
    {{ element }}
    <i
      v-if="element"
      class="fa-light fa-copy cursor-pointer"
      @click.stop="copy"
    ></i>
  </span>
</template>

<script>
export default {
  name: "copy-element",

  components: {},

  mixins: [],

  props: {
    element: {
      type: [String, null, undefined],
      default: null,
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {
    async copy() {
      await this.$copyText(this.element);
      this.$notify({
        type: "success",
        timeout: 3000,
        message: this.$t("COMMON.COPIED"),
      });
    },
  },

  watch: {},
};
</script>

<style scoped>
.object-link {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

.object-link i {
  margin-left: 7px;
  opacity: 1;
  /*transition: opacity 0.3s;*/
}
</style>
