import {
  INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_CHECK,
} from "../constants/inspectionForms";

export const getGoodFieldValue = (field) => {
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET) {
    return "GOOD";
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET) {
    return { value: "GOOD", charge: 1, total_charge: 1 };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET) {
    return {
      value: "GOOD",
      ratio: 11,
      type: "STANDARD",
      season: "SUMMER",
    };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET) {
    return {
      value: "GOOD",
      ratio: 10,
    };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET) {
    return {
      value: "GOOD",
      ratio: 10,
      type: "STANDARD",
    };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_CHECK) {
    const pointsThreshold = field.points_threshold;
    const option = pointsThreshold.reduce(
      (max, obj) => (obj.points > max.points ? obj : max),
      pointsThreshold[0]
    );
    return option.option;
  }
  const pointsThreshold = field.points_threshold;
  if (pointsThreshold && Array.isArray(pointsThreshold)) {
    const option = pointsThreshold.reduce(
      (max, obj) => (obj.points > max.points ? obj : max),
      pointsThreshold[0]
    );
    return option.option;
  }
  return null;
};

export const getUntouchedFields = (inspection) => {
  const untouchedFields = [];

  for (const section of Object.values(inspection.sectionsScores)) {
    for (const subSection of Object.values(section.subSections)) {
      for (const field of Object.values(subSection.fields)) {
        if (!field.touched) {
          untouchedFields.push(field);
        }
      }
    }
  }

  return untouchedFields;
};
