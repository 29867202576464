export default {
  bind: function (el, binding, vnode) {
    const isActive = binding.value ?? false;

    if (isActive) {
      el.addEventListener("focusin", () => {
        // const value = vnode.context[binding.expression];
        const value = el.value;

        if (value == 0) {
          // vnode.context[binding.expression] = "";
          el.value = "";
        }
      });

      el.addEventListener("focusout", () => {
        // const value = vnode.context[binding.expression];
        const value = el.value;

        if (value == "" || value == null || value == undefined) {
          // vnode.context[binding.expression] = 0;
          el.value = 0;
        }
      });
    }
  },
  unbind: function (el) {
    el.removeEventListener("focusin", () => {});
    el.removeEventListener("focusout", () => {});
  },
};
