<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header">
      <div class="search">
        <div class="search-add">
          <base-button
            class="add"
            icon
            size="sm"
            @click="addInspectionForm"
            v-if="$currentUserCan($permissions.PERM_CREATE_INSPECTION_FORMS)"
          >
            <span class="btn-inner--icon">
              <i class="fa-regular fa-circle-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM") }}
            </span>
          </base-button>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>
        <div class="flex-grow-0">
          <base-button
            class="notification"
            type="info"
            icon
            size="sm"
            title="corbeille"
            :class="{ active: onlyTrashed }"
            @click="onlyTrashed = !onlyTrashed"
          >
            <span class="btn-inner--icon">
              <i class="fa-light fa-trash"></i>
            </span>
          </base-button>
        </div>

        <!--<notification-subscription
          v-if="$currentUserCan($permissions.PERM_VIEW_INSPECTION_FORMS)"
          :objectType="'inspectionForms'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />-->
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
          >
            <organization-selector
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATIONS')}`">
              <locations-selector
                @locationsChanged="
                  (locations) => (selectedLocations = locations)
                "
              />
            </base-input>
          </div>
          <div class="advanced-search-item">
            <base-input :placeholder="`${$t('COMMON.TAGS')}`">
              <tags-selector
                @tagsChanged="
                  (tags) => {
                    selectedTags = tags;
                  }
                "
                :organization="selectedOrganization"
              />
            </base-input>
          </div>
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm" @click="getListDebounced">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text"> {{ $t("COMMON.UPDATE") }} </span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>
    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush inspections-forms"
          header-row-class-name="thead-light"
          :data="inspectionForms"
          @sort-change="sortChange"
          :row-class-name="setDeletedClass"
          @row-click="
            (row) => {
              viewInspectionForm(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :label="$t('COMMON.TAGS')"
            prop="tags"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              <tags :tags="row.tags" @tagsUpdated="getList" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.LOCATIONS')"
            sortable="custom"
            min-width="220"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <template v-slot="{ row }">
              <locations :locations="row.allowedLocations" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.ORGANIZATION')"
            sortable="custom"
            min-width="220"
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
          >
            <template v-slot="{ row }">
              <organization :organization="row.organization" />
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <div
              v-if="!isDeleted(row)"
              slot-scope="{ row }"
              class="table-actions"
            >
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                :class="{
                  disabled: !$currentUserCan(
                    $permissions.PERM_VIEW_INSPECTION_FORMS
                  ),
                }"
              >
                <a
                  type="text"
                  @click="viewInspectionForm(row)"
                  class="table-action"
                  data-toggle="tooltip"
                >
                  <i
                    class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                  ></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.DELETE')"
                placement="top"
                :class="{
                  disabled: !$currentUserCan(
                    $permissions.PERM_DELETE_INSPECTION_FORMS
                  ),
                }"
              >
                <a
                  type="text"
                  @click="deleteInspectionForm(row.id)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                >
                  <i class="fa-light fa-trash-can"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="list-table-inner-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import Tags from "@/components/Tags.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import trashedMixin from "@/mixins/trashed-mixin";

export default {
  name: "inspection-form-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    Tags,
    TagsSelector,
    LocationsSelector,
    NotificationSubscription,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, trashedMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      inspectionForms: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedTags: [],
      showAdvancedFilter: false,
      onlyTrashed: false,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    onlyTrashed: {
      handler: "getList",
      immediate: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTags: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,tags",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        if (this.selectedTags) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              tags: this.selectedTags.map((item) => item.id),
            },
          };
        }
        if (this.onlyTrashed) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              only_trashed: true,
            },
          };
        }

        await this.$store.dispatch("inspectionForms/list", params);
        this.inspectionForms = this.$store.getters["inspectionForms/list"];
        this.total = this.$store.getters["inspectionForms/listTotal"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteInspectionForm(id) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("inspectionForms/destroy", id);
          await this.getListDebounced();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewInspectionForm(inspectionForm) {
      if (this.isDeleted(inspectionForm)) {
        return;
      }
      this.$emit("onViewInspectionForm", inspectionForm);
    },

    editInspectionForm(inspectionForm) {
      this.$emit("onEditInspectionForm", inspectionForm);
    },

    addInspectionForm() {
      this.$emit("onAddInspectionForm");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getListDebounced();
    },
  },
};
</script>
