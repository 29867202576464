import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

const includes_full = [
  "organization",
  "creator",
  "customer",
  "request",
  "allowedLocations",
  "vehicle",
  "vehicle.allowedLocations",
  "tags",
  "inspectionForm",
  "inspectionForm.inspectionFormSections",
  "inspectionForm.inspectionFormSections.parent",
  "inspectionForm.inspectionFormSections.subSections",
  "inspectionForm.inspectionFormSections.subSections.inspectionFormSectionFields",
  "inspectionForm.reminders",
  "inspectionFields",
  "inspectionFields.inspectionFormSectionField",
  "assignedToUsers",
  "assignedToRoles",
  "assignedBy",
  "approvedBy",
  "assignedBy",
  "completedBy",
  "canceledBy",
  "startedBy",
  "approver",
  "estimation",
  "repair",
];

const includes_reduced = [
  "organization",
  "creator",
  "customer",
  "request",
  "allowedLocations",
  "vehicle",
  "vehicle.allowedLocations",
  "tags",
  "inspectionForm",
  "inspectionForm.reminders",
  "assignedToUsers",
  "assignedToRoles",
  "assignedBy",
  "approvedBy",
  "assignedBy",
  "completedBy",
  "canceledBy",
  "startedBy",
  "approver",
  "estimation",
  "repair",
];

// const includes_reduced = [
//   "organization",
//   "creator",
//   "customer",
//   "request",
//   "allowedLocations",
//   "vehicle",
//   "vehicle.allowedLocations",
//   "tags",
//   "inspectionForm",
//   "inspectionForm.inspectionFormSections",
//   "inspectionForm.inspectionFormSections.parent",
//   "inspectionForm.inspectionFormSections.subSections",
//   "inspectionForm.inspectionFormSections.subSections.inspectionFormSectionFields",
//   "inspectionForm.reminders",
//   "inspectionFields",
//   "inspectionFields.inspectionFormSectionField",
//   "assignedToUsers",
//   "assignedToRoles",
//   "assignedBy",
//   "approvedBy",
//   "assignedBy",
//   "completedBy",
//   "canceledBy",
//   "startedBy",
//   "approver",
//   "estimation",
//   "repair",
// ];

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/inspections`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get({ id, params }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const paramsData = { ...params };
  if (!paramsData.include) {
    paramsData.include = includes_reduced.join(",");
  }
  options.params = paramsData;

  return axios
    .get(`${url}/inspections/${id}}`, options)
    .then((response) => {
      let inspection = jsona.deserialize(response.data);
      delete inspection.links;
      return inspection;
    });
}

function add(inspection) {
  const payload = jsona.serialize({
    stuff: inspection,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/inspections`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update({ inspection, params }) {
  const payload = jsona.serialize({
    stuff: inspection,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const paramsData = { ...params };
  if (!paramsData.include) {
    paramsData.include = includes_reduced.join(",");
  }
  options.params = paramsData;

  return axios
    .patch(`${url}/inspections/${inspection.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/inspections/${id}`, options);
}

function complete(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/inspections/${id}/complete?include=${includes_reduced.join(",")}`,
      data,
      options
    )
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/inspections/${id}/cancel?include=${includes_reduced.join(",")}`,
      options
    )
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

function getReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/inspections/${id}/report`, options);
}

function assign(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/inspections/${id}/assign?include=${includes_reduced.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function approve(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/inspections/${id}/approve?include=${includes_reduced.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function start(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/inspections/${id}/start?include=${includes_reduced.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function takeOver(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/inspections/${id}/take-over?include=${includes_reduced.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function resetSection(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspections/${id}/reset-section?include=${includes_reduced.join(",")}`, data, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function setSectionGood(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspections/${id}/set-section-good?include=${includes_reduced.join(",")}`, data, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function share(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspections/${id}/share`, data, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function redo(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspections/${id}/redo`, {}, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function getCount(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/inspections/list/count`, options)
    .then((response) => response.data);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  complete,
  cancel,
  getReport,
  assign,
  approve,
  start,
  takeOver,
  resetSection,
  setSectionGood,
  share,
  redo,
  getCount,
};
