var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),(
          !_vm.filterOrganization &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        )?_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1):_vm._e(),_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"organization":_vm.selectedOrganization},on:{"tagsChanged":(tags) => {
              _vm.selectedTags = tags;
            }}})],1),_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.CODE'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('div',{staticClass:"flex-grow-0"},[_c('base-button',{staticClass:"notification",class:{ active: _vm.onlyTrashed },attrs:{"type":"info","icon":"","size":"sm","title":"corbeille"},on:{"click":function($event){_vm.onlyTrashed = !_vm.onlyTrashed}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa-light fa-trash"})])])],1)],1),_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.contacts,"row-class-name":_vm.setDeletedClass},on:{"sort-change":_vm.sortChange,"row-click":(row) => {
              _vm.viewContact(row);
            }}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.NAME'),"prop":"code","sortable":"custom","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(`${row.firstname} ${row.lastname}`)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.EMAIL'),"prop":"email","min-width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PHONE'),"prop":"phone","min-width":"180"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.TAGS'),"prop":"tags","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('tags',{attrs:{"tags":row.tags},on:{"tagsUpdated":_vm.getList}})]}}])}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
              !_vm.filterOrganization &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return (!_vm.isDeleted(row))?_c('div',{staticClass:"table-actions"},[_c('el-tooltip',{class:{
                  disabled: !_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_CONTACTS),
                },attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewContact(row)}}},[_c('i',{staticClass:"fa-light fa-arrow-up-right-and-arrow-down-left-from-center"})])]),_c('el-tooltip',{class:{
                  disabled: !_vm.$currentUserCan(
                    _vm.$permissions.PERM_DELETE_CONTACTS
                  ),
                },attrs:{"content":_vm.$t('COMMON.DELETE'),"placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteContact(row.id)}}},[_c('i',{staticClass:"fa-light fa-trash-can"})])])],1):_vm._e()}}],null,true)})],1)],1)]),_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }