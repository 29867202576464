<template>
  <div class="data-capture-view">
    <base-button class="expand" icon size="sm" @click="toogleScanner">
      <i class="far fa-expand"></i>
    </base-button>
    <div
      :style="{ display: scanOpened ? 'block' : 'none' }"
      id="data-capture-view"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";
import swal from "sweetalert2";
import { Option, Select } from "element-ui";

import {
  Camera,
  CameraSwitchControl,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  configure,
  VideoResolution,
  RectangularViewfinderLineStyle,
  RectangularViewfinderStyle,
  RectangularViewfinder,
} from "scandit-web-datacapture-core";
import {
  BarcodeCapture,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureSettings,
  Symbology,
  SymbologyDescription,
  barcodeCaptureLoader,
} from "scandit-web-datacapture-barcode";

const scanditLicenceKey = process.env.VUE_APP_SCANDIT_LICENCE_KEY;

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {},

  data() {
    return {
      context: null,
      barcodeCapture: null,
      view: null,
      camera: null,
      scanOpened: false,
    };
  },

  setup() {},

  created() {},

  mounted() {
    this.loadAndPrepareLibrary();
  },

  destroyed() {
    this.closeScanner();
  },

  methods: {
    async loadAndPrepareLibrary() {
      // Subscribe to the loading status and update UI accordingly
      // loadingStatus.subscribe(this.loadingStatusSubscriber);

      // Enter your Scandit License key here.
      // Your Scandit License key is available via your Scandit SDK web account.
      // The passed parameter represents the location of the wasm file, which will be fetched asynchronously.
      // You must `await` the returned promise to be able to continue.
      await configure({
        licenseKey: scanditLicenceKey,
        libraryLocation:
          "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.25.0/build/engine/",
        moduleLoaders: [
          barcodeCaptureLoader({ highEndBlurryRecognition: true }),
        ],
      });

      // Unsubscribe to the loading status updates
      // loadingStatus.unsubscribe(this.loadingStatusSubscriber);

      // Create the data capture context.
      this.context = await DataCaptureContext.create();

      // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
      // default and must be turned on to start streaming frames to the data capture context for recognition.
      window.camera = Camera.default;
      const camera = window.camera;
      const cameraSettings = BarcodeCapture.recommendedCameraSettings;
      cameraSettings.preferredResolution = VideoResolution.UHD4K;
      if (camera) {
        await camera.applySettings(cameraSettings);
      }
      await this.context.setFrameSource(camera);

      // The barcode capturing process is configured through barcode capture settings,
      // they are then applied to the barcode capture instance that manages barcode recognition.
      const settings = new BarcodeCaptureSettings();

      // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
      // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
      // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
      settings.enableSymbologies([Symbology.Code39]);

      // Some linear/1D barcode symbologies allow you to encode variable-length data. By default, the Scandit
      // Data Capture SDK only scans barcodes in a certain length range. If your application requires scanning of one
      // of these symbologies, and the length is falling outside the default range, you may need to adjust the "active
      // symbol counts" for this symbology. This is shown in the following few lines of code for one of the
      // variable-length symbologies.
      const symbologySettingsCode39 = settings.settingsForSymbology(
        Symbology.Code39
      );
      symbologySettingsCode39.isColorInvertedEnabled = true;

      // Create a new barcode capture mode with the settings from above.
      this.barcodeCapture = await BarcodeCapture.forContext(
        this.context,
        settings
      );
      // Disable the barcode capture mode until the camera is accessed.
      await this.barcodeCapture.setEnabled(false);

      // Register a listener to get informed whenever a new barcode got recognized.
      this.barcodeCapture.addListener({
        didScan: async (barcodeCaptureMode, session) => {
          const barcode = session.newlyRecognizedBarcodes[0];
          const symbology = new SymbologyDescription(barcode.symbology);
          // Disable the capture of barcodes until the user closes the displayed result.
          await this.barcodeCapture.setEnabled(false);
          this.showResult(symbology, barcode);
        },
      });

      // To visualize the ongoing barcode capturing process on screen, set up a data capture view that renders the
      // camera preview. The view must be connected to the data capture context.
      this.view = await DataCaptureView.forContext(this.context);

      // Connect the data capture view to the HTML element.
      this.view.connectToElement(document.getElementById("data-capture-view"));

      // Add a control to be able to switch cameras.
      this.view.addControl(new CameraSwitchControl());
      this.camera = camera;
    },

    loadingStatusSubscriber(info) {
      if (info.percentage === 100) {
        swal.close();
      }
    },

    showResult(symbology, barcode) {
      console.log(symbology);
      this.$emit("onVinScanned", barcode.data);
      this.closeScanner();
    },

    async toogleScanner() {
      if (this.scanOpened) {
        this.closeScanner();
      } else {
        this.openScanner();
      }
    },

    async openScanner() {
      // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
      // the video preview. This is optional, but recommended for better visual feedback.
      const barcodeCaptureOverlay =
        await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
          this.barcodeCapture,
          this.view,
          BarcodeCaptureOverlayStyle.Frame
        );
      const viewfinder = new RectangularViewfinder(
        RectangularViewfinderStyle.Square,
        RectangularViewfinderLineStyle.Light
      );
      await barcodeCaptureOverlay.setViewfinder(viewfinder);

      await this.camera.switchToDesiredState(FrameSourceState.On);
      await this.barcodeCapture.setEnabled(true);
      this.scanOpened = true;
    },

    async closeScanner() {
      await this.camera.switchToDesiredState(FrameSourceState.Off);
      await this.barcodeCapture.setEnabled(false);
      this.scanOpened = false;
    },
  },

  watch: {},
};
</script>
