<template>
  <div class="vehicle-sku">
    <el-select
      ref="skuSelector"
      v-model="vehicleModel"
      @change="vehicleChanged"
      autocomplete="new-password"
      :placeholder="sku ? sku : $t('VEHICLES.SKU')"
      :filterable="true"
      :multiple="false"
      :disabled="disabled"
      :remote="true"
      :remote-method="getVehiclesDebounced"
      :loading="loading"
    >
      <el-option
        v-for="vehicle in vehicles"
        :key="vehicle.vehicle_id"
        :value="vehicle.vehicle_id"
        :label="$objectDenomination({ ...vehicle, type: 'vehicles' })"
      />
    </el-select>
  </div>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    sku: {
      type: String,
      default: null,
      description: "Sku",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "organizationID",
    },
  },

  data() {
    return {
      vehicleModel: this.sku,
      vehicles: {},
      loading: false,
    };
  },

  setup() {},

  created() {},

  mounted() {},

  methods: {
    getVehiclesDebounced: _.debounce(function (query) {
      this.getVehicles(query);
    }, 500),

    async getVehicles(query = "") {
      if (!query) {
        return;
      }
      try {
        this.loading = true;
        const params = {
          filter: {
            sku: query,
            ...(this.filterOrganization
              ? { organization: this.filterOrganization }
              : {}),
          },
        };
        await this.$store.dispatch("vehicles/list", params);
        const vehiclesArr = this.$store.getters["vehicles/list"];

        let tempArr = [];

        vehiclesArr.forEach((vehicle) => {
          if (
            !tempArr.find(
              (iterateVehicle) =>
                iterateVehicle.model_year == vehicle.model_year &&
                iterateVehicle.make == vehicle.make &&
                iterateVehicle.model == vehicle.model &&
                iterateVehicle.style == vehicle.style &&
                iterateVehicle.series == vehicle.series &&
                iterateVehicle.fuel_type == vehicle.fuel_type &&
                iterateVehicle.transmission == vehicle.transmission
            )
          ) {
            tempArr.push(vehicle);
          }
        });

        this.vehicles = [...tempArr];
        if (this.vehicles.length == 0) {
          this.$emit("skuChanged", query);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    vehicleChanged(vehicle_id) {
      this.skuScanned = false;
      const vehicleData = this.vehicles.find(
        (item) => item.vehicle_id == vehicle_id
      );
      this.$emit("vehicleSelected", vehicleData);
    },
  },

  watch: {
    sku(sku) {
      this.vehicleModel = sku;
    },
  },
};
</script>
