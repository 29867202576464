var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION'),"disabled":_vm.disable}},[_c('organization-selector',{attrs:{"organization":_vm.customer.organization?.id,"filterable":true,"showAll":false,"disabled":_vm.disable},on:{"organizationChanged":(organizationId) => {
            _vm.customer.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION'),"disabled":_vm.disable}},[_c('locations-selector',{attrs:{"locations":_vm.customer.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.customer.organization?.id,"disabled":_vm.disable},on:{"locationsChanged":(locations) => {
            _vm.customer.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')} (*)`,"placeholder":_vm.$t('COMMON.TYPE'),"disabled":_vm.disable},on:{"change":(value) => {
          _vm.onChangeCustomerType(value);
        }}},[_c('el-select',{attrs:{"name":"Type","disabled":_vm.disable},on:{"change":(value) => {
            _vm.onChangeCustomerType(value);
          }},model:{value:(_vm.customer.customer_type),callback:function ($$v) {_vm.$set(_vm.customer, "customer_type", $$v)},expression:"customer.customer_type"}},[_c('el-option',{attrs:{"value":"INDIVIDUAL","label":_vm.$t('CUSTOMERS.CUSTOMER_TYPE_INDIVIDUAL')}}),_c('el-option',{attrs:{"value":"COMPANY","label":_vm.$t('CUSTOMERS.CUSTOMER_TYPE_COMPANY')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer_type}})],1),(_vm.customer.customer_type === _vm.CUSTOMER_TYPE_INDIVIDUAL)?_c('div',{staticClass:"form-group-wrapper"},[_c('span',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.FIRSTNAME')} (*)`,"disabled":_vm.disable},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.firstname),callback:function ($$v) {_vm.$set(_vm.customer, "firstname", $$v)},expression:"customer.firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.firstname}})],1),_c('span',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LASTNAME')} (*)`,"disabled":_vm.disable},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.lastname),callback:function ($$v) {_vm.$set(_vm.customer, "lastname", $$v)},expression:"customer.lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.lastname}})],1)]):_vm._e(),(_vm.customer.customer_type === _vm.CUSTOMER_TYPE_COMPANY)?_c('div',{staticClass:"form-group-wrapper"},[_c('base-input',{staticClass:"form-group-wrapper-item",attrs:{"label":`${_vm.$t('COMMON.COMPANY_NAME')} (*)`,"disabled":_vm.disable},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.customer.company_name),callback:function ($$v) {_vm.$set(_vm.customer, "company_name", $$v)},expression:"customer.company_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.company_name}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`,"disabled":_vm.disable,"type":"email"},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('div',{staticClass:"row equal"},[_c('div',{staticClass:"equal-item type"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE'),"disabled":_vm.disable},on:{"change":(type) => {
                _vm.customer.phone_type = type;
                _vm.onFormChanged();
              }},model:{value:(_vm.customer.phone_type),callback:function ($$v) {_vm.$set(_vm.customer, "phone_type", $$v)},expression:"customer.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"equal-item phone"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')} (*)`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.customer.phone,"disabled":_vm.disable},on:{"phoneNumberChanged":(phone) => {
                _vm.customer.phone = phone;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"equal-item extension"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"disabled":_vm.disable,"inputClasses":'extension-input'},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.customer.phone_extension),callback:function ($$v) {_vm.$set(_vm.customer, "phone_extension", $$v)},expression:"customer.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)])]),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.customer.other_phones,"disabled":_vm.disable},on:{"phoneNumbersChanged":(phoneNumbers) => {
            _vm.customer.other_phones = phoneNumbers;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}})],1),_c('div',{staticClass:"form-wrapper w-100"},[(!_vm.disable)?_c('addresses-selector',{attrs:{"addressValue":_vm.customer.address},on:{"addressInputChangeChanged":_vm.addressInputChangeChanged}}):_vm._e()],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.COUNTRY')} (*)`,"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.customer.country,"filterable":true,"disabled":_vm.disable,"showAll":false},on:{"countryChanged":(country) => {
            _vm.customer.country = country;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.STATE')} (*)`,"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.customer.country,"state":_vm.customer.state,"filterable":true,"disabled":_vm.disable,"showAll":false},on:{"stateChanged":(state) => {
            _vm.customer.state = state;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.state}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CITY')} (*)`,"disabled":_vm.disable},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.customer.city),callback:function ($$v) {_vm.$set(_vm.customer, "city", $$v)},expression:"customer.city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ZIPCODE')} (*)`,"disabled":_vm.disable},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.customer.zipcode),callback:function ($$v) {_vm.$set(_vm.customer, "zipcode", $$v)},expression:"customer.zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.zipcode}})],1),(false)?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAGS')}`,"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"tags":_vm.customer.tags,"disabled":_vm.disable || !_vm.customer.organization?.id,"organization":_vm.customer.organization?.id},on:{"tagsChanged":(tags) => {
            _vm.customer.tags = tags;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1):_vm._e(),_c('h2',{staticClass:"col-12"},[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))]),_c('div',{staticClass:"form-wrapper full"},[_c('base-checkbox',{attrs:{"disabled":_vm.disable},on:{"input":_vm.applyCustomerDataToBilling},model:{value:(_vm.customer.same_address),callback:function ($$v) {_vm.$set(_vm.customer, "same_address", $$v)},expression:"customer.same_address"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("CUSTOMERS.BILLING_INFORMATIONS_SAME_AS_CUSTOMER"))+" ")])])],1),(!_vm.customer.same_address)?_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.customer,"formErrors":_vm.formErrors,"type":_vm.customer.customer_type,"disabled":_vm.disable},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}):_vm._e(),_vm._t("submit-button",function(){return [_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.customer.id ? _vm.$t("CUSTOMERS.EDIT_CUSTOMER") : _vm.$t("CUSTOMERS.ADD_CUSTOMER"))+" ")])],1)]},{"loading":_vm.loading,"disable":_vm.disable})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }