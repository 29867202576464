export default {
  VEHICLES_LIST: "Vehicle List",
  ADD_VEHICLE: "Add Vehicle",
  VEHICLE_ADDED: "Vehicle Added",
  EDIT_VEHICLE: "Edit Vehicle",
  VEHICLE_UPDATED: "Vehicle Updated",
  DELETE_THIS_VEHICLE: "Delete this Vehicle?",
  VEHICLE_DELETED: "Vehicle Deleted",
  VIN: "VIN",
  MAKE: "Make",
  MODEL: "Model",
  SERIES: "Series",
  MODEL_YEAR: "Model Year",
  STYLE: "Style",
  ENGINE_DESCRIPTION: "Engine",
  FUEL_TYPE: "Fuel Type",
  TRANSMISSION: "Transmission",
  DRIVETRAIN: "Drivetrain",
  CLASS_CODE: "Vehicle Class Code",
  CLASS_NAME: "Vehicle Class",
  VIEW_VEHICLE: "View Vehicle",
  VEHICLE_AND_VIN: "Vehicle and VIN",
  SEARCH_BY_VIN: "Search by VIN",
  SKU: "SKU",
  REGISTRATION_NUMBER: "Registration Number",
  ODOMETER: "Odometer",
  ODOMETER_UNIT: "Odometer Unit",
  COLOR: "Color",
  VEHICLE_IDENTIFICATION: "Vehicle Identification",
  PICTURES: "Vehicle Pictures",

  MAKE_VEHICLE_ESTIMATION: "Create Vehicle Estimate",
  ESTIMATE_VEHICLE: "Estimate Vehicle",

  KMS: "Kilometers",
  KMS_RATIO: "Kilometer Ratio",
  ESTIMATE: "Estimate",
  KMS_MIN: "Min Kilometers",
  KMS_MAX: "Max Kilometers",
  KMS_AVG: "Average Kilometers",
  KMS_AVG_RATIO: "Average Kilometer Ratio",
  KMS_AVG_MIN: "Min Average Kilometers",
  KMS_AVG_MAX: "Max Average Kilometers",
  ESTIMATE_SOLD: "Estimated Sale Price",
  ESTIMATE_SOLD_ACTIVE: "Current Price",
  ESTIMATE_SOLD_ESS: "ESS Estimate",

  VEHICLE_ESTIMATE: "Estimate",

  AUTOMATIC_TRANSMISSION: "Automatic",
  MANUAL_TRANSMISSION: "Manual",

  FORBIDDEN_ERRORS: {
    CREATE: "You do not have permission to add a vehicle",
    UPDATE: "You do not have permission to edit the vehicle",
    VIEW: "You do not have permission to view vehicle details",
  },
  SEARCH_BY_SKU: "Search by SKU",
  SKU_NUMBER: "SKU Number",
  QUICK_REQUEST: "Quick Request",
  DEALER: "Dealer",
  SELECT_ALL: "Select All",
  SELECT_DEALER: "Select Dealer",
  DEPARTMENT: "Department",
  MECHANICAL: "Mechanical",
  VEHICLE_IDENTIFICATION: "Vehicle Identification",
};
