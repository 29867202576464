<template>
  <view-object-ref-widget
    :image="repair.vehicle?.gallery[0] ?? null"
    :title="$objectDenomination(repair.vehicle)"
  >
    <template #second-title>
      <ul>
        <li class="d-inline-block">
          {{ repair.odometer }}
          {{ repair.odometer_unit ?? repair.vehicle?.odometer_unit }}
        </li>
        <li class="line d-inline-block mx-2">|</li>
        <li class="d-inline-block">
          <copy-element :element="repair.vehicle?.vin" />
        </li>
      </ul>
      <h4>{{ title ?? repair.code }}</h4>
    </template>
    <template #buttons>
      <slot name="buttons">
        <!--<button v-if="canCancel" @click="$emit('cancel')" class="delete">
        <span> {{ $t("COMMON.CANCEL") }} </span>
        <i class="far fa-trash-alt"></i>
      </button>-->
        <button v-if="canEdit" @click="$emit('edit')" class="edit">
          <span> {{ $t("COMMON.UPDATE") }} </span>
          <i class="far fa-edit"></i>
        </button>
        <button v-if="canView" @click="view" class="edit">
          <span> {{ $t("ESTIMATES.SHOW_ESTIMATE") }} </span>
          <i class="fa-light fa-eye"></i>
        </button>
      </slot>
    </template>
  </view-object-ref-widget>
</template>

<script>
import CopyElement from "./CopyElement.vue";
import ViewObjectRefWidget from "./ViewObjectRefWidget.vue";
import { QUERY_ACTIONS_EDIT } from "@/constants/common";

export default {
  name: "repair-ref-standardized",

  components: { ViewObjectRefWidget, CopyElement },

  props: {
    repair: {
      type: Object,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      QUERY_ACTIONS_EDIT,
    };
  },

  computed: {},

  methods: {
    view() {
      this.$emit("onView");
      if (this.$route.name != "List Repairs") {
        this.$router.push({
          name: "List Repairs",
          query: { id: this.repair.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
