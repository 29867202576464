export default {
  PROFILE_UPDATED: "Profile Updated",
  YOUR_SECURITY: "Your Security",
  ENABLE_2FA: "Enable Two-Factor Authentication",
  ENABLE_2FA_AT: "Enabled on",
  DISABLE_2FA: "Disable Two-Factor Authentication",
  DISABLE_2FA_AT: "Disabled on",
  ENABLE_2FA_SUCCESS:
    "Congratulations! You have successfully enabled two-factor authentication to further secure your account. Your account is now more secure.",
  DISABLE_2FA_SUCCESS:
    "Two-factor authentication has been successfully disabled. You can now log in without entering an additional code.",
};
