var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer-form"},[_c('div',{staticClass:"customer-form-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t("CUSTOMERS.CUSTOMER_INFORMATION")))])]),_c('div',{staticClass:"customer-form-divider"}),_c('p',{staticClass:"customer-form-radio"},[_c('base-radio',{attrs:{"disabled":_vm.disabled,"value":_vm.INTERNAL,"name":_vm.INTERNAL,"inline":""},model:{value:(_vm.customerType),callback:function ($$v) {_vm.customerType=$$v},expression:"customerType"}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.INTERNAL"))+" ")]),_c('base-radio',{attrs:{"disabled":_vm.disabled,"name":_vm.EXISTING_CUSTOMER,"value":_vm.EXISTING_CUSTOMER,"inline":""},model:{value:(_vm.customerType),callback:function ($$v) {_vm.customerType=$$v},expression:"customerType"}},[_vm._v(" "+_vm._s(_vm.$t("CUSTOMERS.EXISTING_CUSTOMER"))+" ")]),_c('base-radio',{attrs:{"value":_vm.NEW_CUSTOMER,"name":_vm.NEW_CUSTOMER,"disabled":_vm.disabled,"inline":""},model:{value:(_vm.customerType),callback:function ($$v) {_vm.customerType=$$v},expression:"customerType"}},[_vm._v(" "+_vm._s(_vm.$t("CUSTOMERS.NEW_CUSTOMER"))+" ")])],1),(_vm.customerType == _vm.EXISTING_CUSTOMER)?_c('div',{staticClass:"select-customer"},[_c('div',{staticClass:"d-flex gap-4 align-items-end justify-content-between"},[_c('div',{staticClass:"w-100"},[_c('base-input',{attrs:{"label":`${_vm.$t('CUSTOMERS.SELECT_CUSTOMER')}`,"placeholder":_vm.$t('CUSTOMERS.SELECT_CUSTOMER')}},[_c('customer-selector',{attrs:{"allowNone":false,"customer":_vm.object.customer?.id,"disabled":_vm.disabled,"filterOrganization":_vm.object.organization?.id ?? null,"filterable":true,"showAll":false,"ignore-location-filter":_vm.isRestrictToOneLocation,"with-doesnt-have":""},on:{"customerChanged":_vm.getCustomer}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1),(
          _vm.$idExist(_vm.object.customer?.id) &&
          _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_CUSTOMERS)
        )?_c('div',{staticClass:"inspection-form-group next-button p-0 pb-4 w-auto justify-content-end border-top-0"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"size":"sm","type":"button","disabled":_vm.disabled},on:{"click":_vm.updateCustomer}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.showEditCustomerForm ? _vm.$t("COMMON.CANCEL") : _vm.$t("CUSTOMERS.EDIT_CUSTOMER"))+" ")])])],1):_vm._e()]),(
        _vm.$idExist(_vm.object.customer?.id) &&
        _vm.showEditCustomerForm &&
        _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_CUSTOMERS)
      )?_c('div',{staticClass:"px-3"},[_c('customer-form',{attrs:{"customerData":_vm.object.customer,"disable":_vm.disabled || !_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_CUSTOMERS),"formErrors":_vm.formErrorsValue,"loading":_vm.loading},on:{"customerSubmitted":_vm.handleCustomerFormSubmit,"formChanged":_vm.customerChanged},scopedSlots:_vm._u([{key:"customer-form-title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("CUSTOMERS.CUSTOMER_INFORMATION")))])]},proxy:true},{key:"submit-button",fn:function(){return [_c('span')]},proxy:true}],null,false,2614300900)})],1):_vm._e()]):_vm._e(),(_vm.customerType == _vm.INTERNAL)?_c('div',{staticClass:"select-customer"},[_c('base-input',{attrs:{"label":`${_vm.$t('INSPECTIONS.SELECT_APPROVER')}`,"placeholder":_vm.$t('INSPECTIONS.SELECT_APPROVER')}},[_c('user-selector',{attrs:{"allow-none":false,"disabled":_vm.disabled,"filter-organization":_vm.object.organization?.id ?? null,"filter-permission":_vm.$permissions.PERM_APPROVE_ESTIMATIONS,"filter-location":_vm.object.allowedLocations[0]?.id ?? null,"filterable":true,"show-all":false,"user":_vm.object.approver?.id,"ignore-location-filter":_vm.isRestrictToOneLocation,"with-doesnt-have":""},on:{"userChanged":_vm.getApprover}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1):_vm._e(),(_vm.customerType == _vm.NEW_CUSTOMER)?_c('customer-form',{attrs:{"customerData":_vm.object.customer,"disable":_vm.disabled || !_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_CUSTOMERS),"formErrors":_vm.formErrorsValue,"loading":_vm.loading},on:{"customerSubmitted":_vm.handleCustomerFormSubmit,"formChanged":_vm.customerChanged},scopedSlots:_vm._u([{key:"customer-form-title",fn:function(){return [_c('span')]},proxy:true},{key:"submit-button",fn:function(){return [_c('span')]},proxy:true}],null,false,1173270964)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }