import { render, staticRenderFns } from "./InspectionRefStandardized.vue?vue&type=template&id=1924ee54&scoped=true"
import script from "./InspectionRefStandardized.vue?vue&type=script&lang=js"
export * from "./InspectionRefStandardized.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1924ee54",
  null
  
)

export default component.exports