export default {
  LOCATIONS_LIST: "Dealership List",
  ADD_LOCATION: "Add Dealership",
  LOCATION_ADDED: "Dealership Added",
  LOCATIONS_UPDATED: "Dealership Updated",
  EDIT_LOCATION: "Edit Dealership",
  DELETE_THIS_LOCATION: "Delete this Dealership?",
  LOCATION_DELETED: "Dealership Deleted",
  VIEW_LOCATION: "View Dealership",
  NONE: "None",
};
