<template>
  <div class="inspection-form">
    <customer-step-form-component
      :object-data.sync="inspectionData"
      :form-errors="errors"
      :disabled="!canEditInspection"
    />
    <div class="opacity-0 p-2">
      <Button @click.prevent="() => {}" />
    </div>
    <div class="add-inspection-steps-content">
      <div class="steps-content-footer">
        <div class="mr-auto align-content-center">
          <Button
            class="next"
            :class="{ 'opacity-5 cursor-not-allowed': !canUpdateCustomer }"
            :disabled="!canUpdateCustomer"
            @click="handleSubmit"
          >
            <i
              class="fas fa-spinner fa-spin mr-1"
              v-if="isLoading || loading"
            />
            {{ $t("COMMON.UPDATE") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertLeave from "@/mixins/alert-leave-mixin";
import {
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";
import { cloneDeep } from "lodash";
import { isEqual } from "lodash";
import swal from "sweetalert2";
import {
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_INDIVIDUAL,
} from "@/constants/customers";
import CustomerStepFormComponent from "@/components/CustomerStepFormComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CustomerStepFormComponent,
  },

  mixins: [alertLeave],

  props: {
    inspection: {
      type: Object,
      required: true,
    },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const inspectionData = cloneDeep(this.inspection);
    const errors = cloneDeep(this.formErrors);
    const isLoading = false;
    return {
      errors,
      inspectionData,
      isLoading,
    };
  },

  created() {},

  computed: {
    canEditInspection() {
      return (
        ![INSPECTION_STATUS_COMPLETED, INSPECTION_STATUS_CANCELED].includes(
          this.inspectionData.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },

    canUpdateCustomer() {
      return (
        this.canEditInspection &&
        (this.$idExist(this.inspectionData.customer?.id) ||
          this.$idExist(this.inspectionData.approver?.id) ||
          !!this.inspectionData.customer?.organization)
      );
    },
  },

  methods: {
    async handleSubmit() {
      if (!this.canEditInspection) {
        return;
      }
      this.errors = null;
      this.isLoading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.inspectionData.approver?.id)) {
          if (!!this.inspectionData.customer?.organization) {
            await this.handleCustomerFormSubmit(this.inspectionData.customer);
          }
          this.inspectionData.approver = null;
        } else {
          this.inspectionData.customer = null;
        }

        const inspectionData = cloneDeep(this.inspectionData);

        delete inspectionData.request;

        this.$emit("updateInspection", inspectionData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.errors = error.response?.data?.errors;
        swal.close();
      } finally {
        this.isLoading = false;
      }
    },

    async handleCustomerFormSubmit(customerData) {
      customerData = { ...this.inspectionData.customer, ...customerData };

      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      let customer = null;

      if (this.$idExist(customerData?.id)) {
        await this.$store.dispatch("customers/update", customerData);
      } else {
        delete customerData.id;
        await this.$store.dispatch("customers/add", customerData);
      }
      customer = await this.$store.getters["customers/customer"];

      this.inspectionData.customer = {
        ...this.inspectionData.customer,
        ...customer,
      };
    },
  },

  watch: {
    inspection() {
      if (!isEqual(this.inspectionData, this.inspection)) {
        this.inspectionData = cloneDeep(this.inspection);
      }
    },
    formErrors() {
      this.errors = cloneDeep(this.formErrors);
    },
  },
};
</script>
<style>
.opacity-0 {
  opacity: 0 !important;
}
</style>
