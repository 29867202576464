<template>
  <div class="item-inner" :id="`field_${fieldData.id}`">
    <div class="item-inner-text">
      <p>{{ fieldData.name }}</p>
    </div>
    <div class="item-inner-right">
      <div class="custom-loading" v-if="loading">
        <div class="custom-loading-icon"></div>
        <p>{{ $t("COMMON.LOADING") }}</p>
      </div>
      <base-button
        :class="{
          'is-filled': fieldData.images.length > 0,
        }"
        :disabled="!fieldData.can_upload_image"
        icon
        size="sm"
        @click="showPictureModal = true"
      >
        <i class="far fa-camera"></i>
      </base-button>

      <base-button
        :class="{ 'is-filled': !!fieldData.comment }"
        :disabled="!fieldData.can_add_comment"
        icon
        size="sm"
        @click="showActionModal = true"
      >
        <i v-if="!fieldData.comment" class="far fa-comment"></i>
        <i v-if="!!fieldData.comment" class="far fa-comment"></i>
      </base-button>

      <field-widget
        :fieldForm="fieldData"
        :defaultValue="fieldData.value"
        :defaultActionValue="fieldData.action"
        :disabled="disabled"
        :commentValue="fieldData.comment"
        :touched="fieldData.touched"
        @change="valueChanged"
        @actionChanged="actionChanged"
      />
    </div>

    <action-comment-widget
      :showActionModal="showActionModal"
      :disabled="disabled"
      :actionValue="fieldData.action"
      :commentValue="fieldData.comment"
      :fieldValue="getValue()"
      @actionCanceled="cancelAction"
      @actionChanged="changeAction"
    />

    <div
      class="battery-status-wrapper"
      v-if="
        fieldData.field_type == INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET
      "
    >
      <base-input :value="fieldData.value?.charge" :disabled="true">
      </base-input>
      <div class="form-group-separator" :disabled="true">/</div>
      <base-input :value="fieldData.value?.total_charge" :disabled="true">
      </base-input>
      <base-input
        class="units"
        :placeholder="$t('INSPECTIONS.UNIT')"
        :disabled="true"
      >
        <el-select
          :value="fieldData.value?.unit"
          :placeholder="$t('INSPECTIONS.UNIT')"
          :disabled="true"
        >
          <el-option class="" value="AMPS">
            {{ $t("INSPECTIONS.AMPS") }}
          </el-option>
          <el-option class="" value="VOLTS">
            {{ $t("INSPECTIONS.VOLTS") }}
          </el-option>
        </el-select>
      </base-input>
    </div>

    <modal
      :showClose="true"
      :show.sync="showPictureModal"
      modal-classes="picture"
    >
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="images"
          ressource_name="inspection_form_fields"
          :ressource_id="fieldData.id ? fieldData.id : 0"
          :field="fieldData.name"
          :disabled="disabled"
          @galleryChanged="
            (gallery_urls) => {
              images = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer">
        <!-- <base-button
          type="secondary"
          class="btn close"
          @click="showPictureModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button> -->
        <base-button
          v-if="!disabled"
          type="primary"
          class="btn save"
          @click="addImages"
        >
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { cloneDeep, debounce, isEqual } from "lodash";
import { Button, Select, Option } from "element-ui";
import { INSPECTION_STATUS_IN_PROGRESS } from "@/constants/inspections";
import {
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
} from "@/constants/inspectionForms";
import GallerySelector from "@/components/GallerySelector.vue";
import FieldWidget from "./fieldsWidgets/FieldWidget.vue";
import ActionCommentWidget from "./fieldsWidgets/ActionCommentWidget.vue";

export default {
  name: "inspection-view-inspection-section-sub-section-field-view",

  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    GallerySelector,
    FieldWidget,
    ActionCommentWidget,
  },

  props: ["fieldData", "disabled", "inspection", "settingLoading"],

  data() {
    const refKey = uniqueId([]);
    return {
      showPictureModal: false,
      showActionModal: false,
      fieldModel: cloneDeep(this.fieldData),
      images: cloneDeep(this.fieldData.images),
      refKey: refKey,
      loading: false,
      INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET:
        INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
    };
  },

  computed: {},

  created() {},

  methods: {
    valueChanged(value) {
      if (!isEqual(value, this.fieldModel.value)) {
        this.fieldModel.value = value;
        this.fieldModel.touched = true;
        this.fieldModel.comment = "";
        this.comment = "";
        this.radioComment = null;
        this.updateFieldDebounced();
      }
    },

    getValue() {
      if (
        [
          INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
          INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
          INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
        ].includes(this.fieldData.field_type)
      ) {
        return this.fieldData.value?.value;
      }
      return this.fieldData.value;
    },

    actionChanged(action, comment) {
      if (
        !isEqual(action, this.fieldModel.action) ||
        !isEqual(comment, this.fieldModel.comment)
      ) {
        this.fieldModel.action = action;
        this.fieldModel.comment = comment;
        this.updateFieldDebounced();
      }
    },

    addImages() {
      this.fieldModel.images = cloneDeep(this.images);
      this.showPictureModal = false;
      this.updateFieldDebounced();
    },

    cancelAction() {
      this.showActionModal = false;
    },

    changeAction(action, comment) {
      this.fieldModel.action = action;
      this.fieldModel.comment = comment;
      this.showActionModal = false;
      this.updateFieldDebounced();
    },

    updateFieldDebounced: debounce(function () {
      this.updateField();
    }, 500),

    async updateField() {
      if (
        this.settingLoading ||
        this.inspection.status != INSPECTION_STATUS_IN_PROGRESS
      ) {
        return;
      }
      this.loading = true;
      try {
        const fieldData = {
          type: "inspection-fields",
          id: this.fieldModel.inspection_field_id.toString(),
          images: this.fieldModel.images,
          comment: this.fieldModel.comment,
          value: this.fieldModel.value,
          action: this.fieldModel.action,
          field_type: this.fieldModel.field_type,
          touched: true,
        };
        await this.$store.dispatch("inspectionFields/update", fieldData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },

    async updateLinkedFields(fieldData) {
      const fieldType = fieldData.field_type;
      const canBeLinked = [
        INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
        INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
        INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
      ].includes(fieldType);

      if (!canBeLinked) {
        return;
      }

      let fieldSubSection = null;
      for (const section of Object.values(this.inspection.sectionsScores)) {
        for (const subSection of Object.values(section.subSections)) {
          for (const field of Object.values(subSection.fields)) {
            if (field.id == fieldData.id) {
              fieldSubSection = subSection;
              break;
            }
          }
        }
      }

      const linkedFields = {};
      for (const field of Object.values(fieldSubSection.fields)) {
        if (field.field_type == fieldData.field_type) {
          linkedFields[field.id] = field;
        }
      }

      const firstFieldId = Object.keys(linkedFields).sort((a, b) => {
        return linkedFields[a].order_number - linkedFields[b].order_number;
      })[0];

      if (firstFieldId == fieldData.id) {
        const fieldToChange = Object.values(linkedFields);
        fieldToChange.shift();

        for (const fData of fieldToChange) {
          const updatedfieldData = {
            type: "inspection-fields",
            id: fData.inspection_field_id.toString(),
            images: fieldData.images,
            comment: fieldData.comment,
            value: fieldData.value,
            action: fieldData.action,
            field_type: fieldData.field_type,
          };
          await this.$store.dispatch(
            "inspectionFields/update",
            updatedfieldData
          );
        }
      }
    },
  },

  mounted() {},

  watch: {
    fieldData() {
      if (!isEqual(this.fieldModel, this.fieldData)) {
        this.fieldModel = cloneDeep(this.fieldData);
        this.images = cloneDeep(this.fieldData.images);
      }
    },
  },
};
</script>
