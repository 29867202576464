<template>
  <div>
    <vehicle-form
      :disable-location="disableLocation"
      :disable="disabled"
      :formErrors="formErrorsValue"
      :loading="loading"
      :location-options="currentUserRestrictLocations"
      :location="locationId"
      :organization="globalOrganization?.id"
      :saveText="vehicleSaveBtn"
      :vehicleData="repair.vehicle"
      @formChanged="vehicleChanged"
      @vehicleHasInProgressRepairs="vehicleHasInProgressRepairs"
      @vehicleSubmitted="handleVehicleFormSubmit"
    >
      <template #submit-button><span></span></template>
    </vehicle-form>

    <div class="inspection-form-divider"></div>

    <div class="inspection-form-title">
      <h3>{{ $t("COMMON.WORK_ORDER") }}</h3>
    </div>

    <div class="inspection-form-divider" />

    <div>
      <div class="d-md-flex align-items-center">
        <h5 class="mr-3 mb-0">{{ $t("COMMON.ENTER_WORK_ORDER") }}:</h5>

        <base-input
          class="flex-grow-1"
          :disabled="disabled"
          v-model="repair.work_order"
          :placeholder="$t('COMMON.WORK_ORDER')"
        />
      </div>
      <validation-error :errors="apiValidationErrors.work_order" />
    </div>

    <inspection-confirmation-modal
      :confirm-action="showOtherExistingRepair"
      :cancel-action="closeModal"
      :confirm-button-text="$t('REPAIRS.SHOW_REPAIR')"
      :cancel-button-text="$t('COMMON.OK')"
      :loading="loading"
      :message="$t('REPAIRS.REPAIR_ALREADY_EXIST')"
      :open.sync="showVehicleRepairExistAlertModal"
      modal-classes="modal-secondary validate"
    />
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import VehicleForm from "@/views/Pages/VehiclesModule/VehicleManagement/partials/VehicleForm.vue";
import formMixin from "@/mixins/form-mixin";
import defaultVehicle from "@/views/Pages/VehiclesModule/VehicleManagement/defaultVehicle";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import locationMixin from "@/mixins/location-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "repair-form-vehicle-step",

  components: { VehicleForm, InspectionConfirmationModal, ValidationError },

  mixins: [formMixin, locationMixin],

  props: {
    repairData: { type: Object },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const repair = {
      ...this.repairData,
      vehicle: {
        ...defaultVehicle,
        ...this.repairData.vehicle,
        organization: {
          ...this.repairData.vehicle?.organization,
          ...this.repairData.organization,
        },
      },
    };
    const formErrorsValue = cloneDeep(this.formErrors);
    return {
      repair,
      loading: false,
      formErrorsValue,
      existOtherRepairId: null,
      showVehicleRepairExistAlertModal: false,
    };
  },

  computed: {
    vehicleSaveBtn() {
      if (!this.repair.vehicle?.id || isNaN(this.repair.vehicle.id)) {
        return this.$t("VEHICLES.ADD_VEHICLE");
      } else {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      }
    },
    disableLocation() {
      return (
        !!this.repair.id ||
        this.isRestrictToOneLocation ||
        !!this.globalLocation?.id
      );
    },
    locationId() {
      if (this.repair?.id) {
        if (this.repair?.allowedLocations?.length) {
          return this.repair.allowedLocations[0]?.id;
        }

        return null;
      }

      return this.globalLocation?.id;
    },
  },

  methods: {
    async handleVehicleFormSubmit(vehicleData) {
      this.loading = true;
      vehicleData = { ...this.repair.vehicle, ...vehicleData };
      let vehicle = null;
      try {
        if (this.$idExist(vehicleData?.id)) {
          delete vehicleData.organization;
          await this.$store.dispatch("vehicles/update", vehicleData);
          vehicle = await this.$store.getters["vehicles/vehicle"];
        } else {
          delete vehicleData.id;
          await this.$store.dispatch("vehicles/add", vehicleData);
          vehicle = await this.$store.getters["vehicles/vehicle"];
        }

        this.vehicleChanged(vehicle);

        this.repair.gallery = vehicle?.gallery;
        this.repair.odometer = vehicle?.odometer;
        this.repair.odometer_unit = vehicle?.odometer_unit;
        this.repair.registration_number = vehicle?.registration_number;
        this.repair.color = vehicle?.color;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrorsValue = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    vehicleChanged(vehicleData) {
      this.repair.vehicle = { ...this.repair.vehicle, ...vehicleData };
      this.repair.organization = {
        ...this.repair.organization,
        ...vehicleData.organization,
      };
      this.repair.allowedLocations = cloneDeep(vehicleData.allowedLocations);
    },

    vehicleHasInProgressRepairs(repairIds) {
      if (!!repairIds && typeof repairIds == "object") {
        if (this.$idExist(this.repair.id)) {
          delete repairIds[this.repair.id];
        }

        if (Object.keys(repairIds).length > 0) {
          this.existOtherRepairId = Object.keys(repairIds)[0];

          if (this.existOtherRepairId) {
            this.showVehicleRepairExistAlertModal = true;
          }
        }
      }
    },
    showOtherExistingRepair() {
      return this.$emit("onViewRepair", {
        id: this.existOtherRepairId,
        type: "repairs",
      });
    },

    closeModal() {
      this.$emit("onCloseRepairModal");
    },
  },

  watch: {
    repair: {
      handler: function () {
        this.$emit("update:repairData", this.repair);
      },
      deep: true,
    },

    repairData: {
      handler: function (value) {
        if (_.isEqual(value, this.repair)) return;
        this.repair = { ...this.repair, ...value };
      },
      deep: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
      this.formErrorsValue = cloneDeep(errors);
    },
  },
};
</script>

<style lang="scss" scoped></style>
