import defaultVehicle from "@/views/Pages/VehiclesModule/VehicleManagement/defaultVehicle";

export default {
  type: "estimations",
  excerpt: "",
  work_order: "",
  odometer: 0,
  relationshipNames: [
    "organization",
    "customer",
    "approver",
    "vehicle",
    "inspection",
    "allowedLocations",
  ],
  vehicle: defaultVehicle,
  organization: {
    type: "organizations",
    id: null,
  },
  customer: {
    type: "customers",
    id: null,
  },
  approver: {
    type: "users",
    id: null,
  },
  inspection: {
    type: "inspections",
    id: null,
  },
  allowedLocations: [],
};
