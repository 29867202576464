<template>
  <div class="estimate-details">
    <div class="add-inspection-steps-header">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step
          :title="$t('ESTIMATES.COMPONENTS')"
          :class="{ 'cursor-pointer': !unSaveItemsCount }"
          @click.native="switchToStep(COMPONENTS_STEP)"
        />
        <el-step
          :title="$t('ESTIMATES.TIMES')"
          :class="{ 'cursor-pointer': !unSaveItemsCount }"
          @click.native="switchToStep(TIMES_STEP)"
        />
        <el-step
          :title="$t('COMMON.APPROVAL')"
          :class="{ 'cursor-pointer': !unSaveItemsCount }"
          @click.native="switchToStep(APPROVAL_STEP)"
        />
      </el-steps>
    </div>
    <div class="add-inspection-steps-content">
      <div class="mt-3 pb-4">
        <div class="inspection-form-title">
          <h3>{{ $t("COMMON.RESPONSIBLE") }}</h3>
        </div>

        <div class="inspection-form-divider"></div>

        <estimate-view-estimate-managers
          :estimate="estimate"
          @onChangeComponentsEstimateManager="onChangeComponentsEstimateManager"
          @onChangeTimesEstimateManager="onChangeTimesEstimateManager"
        />
      </div>
      <div v-if="active == COMPONENTS_STEP">
        <div class="position-relative">
          <div
            class="estimate-overlay position-absolute w-100 h-100 top-0 p-6 z-10 d-flex flex-column align-items-center justify-content-center"
            v-if="otherWorkOnComponents"
          >
            <img
              class="mb-4"
              src="/img/estimation-in-progress.svg"
              alt="estimation in progress"
            />
            <span class="h1 text-darker">
              {{ $t("ESTIMATES.OTHER_WORK_ON_ESTIMATE") }}
            </span>
            <div
              class="inspection-form-group next-button justify-content-center"
            >
              <base-button
                class="btn btn-sm elite-submit"
                size="sm"
                type="button"
                @click="showTakeOverComponentsModal = true"
                v-if="canTakeOverComponentsEstimate(estimate)"
              >
                {{ $t("ESTIMATES.TAKE_OVER_ESTIMATE") }}
              </base-button>
            </div>
          </div>
          <div
            class="estimate-details-body"
            :class="{ 'blur-sm': otherWorkOnComponents }"
          >
            <div class="estimate-details-body-header">
              <div class="estimate-details-body-header-left">
                <h3>{{ $t("ESTIMATES.ESTIMATE_DETAIL") }}</h3>
              </div>
              <div class="estimate-details-body-header-right">
                <div
                  class="d-flex align-items-center"
                  v-if="canEditTimes(estimate)"
                >
                  <span class="mr-2">
                    {{ $t("ESTIMATES.ESTIMATE_ITEM_HOURLY_RATE") }}
                  </span>
                  <div>
                    <base-input
                      placeholder="0"
                      v-model.number="hourlyRate"
                      :disabled="!canEditTimes(estimate)"
                      class="clear-input-number-arrows"
                      append-icon="$"
                      input-classes="border-right-0 p-2 text-right"
                      input-group-classes="text-black border-2 purge-append-border input-group-text-p-2 w-90px"
                      min="0"
                      type="number"
                    >
                      <template #append
                        ><span class="text-black">$</span></template
                      >
                    </base-input>
                  </div>
                </div>
                <base-button
                  class="btn btn-sm text-nowrap"
                  type="button"
                  v-if="canGenerateInspectionReport"
                  @click="downloadInspectionReport"
                >
                  {{ $t("ESTIMATES.INSPECTION_REPORT") }}
                  <i class="far fa-file-pdf"></i>
                </base-button>
              </div>
            </div>
            <div class="custom-separator-bold"></div>
            <div class="estimate-details-body-content">
              <p
                class="h1 p-6 d-flex flex-column justify-content-center w-100 align-items-center"
                v-if="estimate.without_components"
              >
                <img
                  src="/img/no-components.svg"
                  alt="estimation in progress"
                />
                <span> {{ $t("ESTIMATES.NO_COMPONENTS_ADDED") }} </span>
              </p>
              <div class="list" v-else>
                <div class="view-form" ref="estimate_items_form">
                  <estimate-item-form
                    v-for="(item, index) in items"
                    ref="items"
                    :key="index"
                    :item.sync="item"
                    :can-edit-status="false"
                    :can-show-status="!!item.status"
                    :can-edit-components="
                      !itemIsApproved(item) && canEditComponents(estimate)
                    "
                    :can-edit-times="
                      !itemIsApproved(item) && canEditTimes(estimate)
                    "
                    :can-remove-item="
                      !itemIsApproved(item) &&
                      canEditComponents(estimate) &&
                      items.length > 1
                    "
                    :without-components="estimate.without_components"
                    :isLastItem="
                      canEditComponents(estimate) && lastItemKey == index
                    "
                    @update:item="
                      (itemModel) =>
                        itemChanged(index, itemModel, COMPONENTS_STEP)
                    "
                    @openCommentModal="openCommentModal"
                    @deleteItem="deleteItem(index)"
                    @addNewItem="addItem(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="steps-content-footer">
          <div class="mr-auto align-content-center">
            <div
              class="d-inline-flex flex-nowrap mr-3 align-text-top align-items-center"
              v-if="canEditComponents(estimate)"
            >
              <base-switch v-model="withoutComponents" on-text="" off-text="" />
              <span
                class="ml-2 h4 mb-0 cursor-pointer"
                @click="toggleWithoutComponents"
              >
                {{ $t("ESTIMATES.ESTIMATE_WITHOUT_COMPONENTS") }}
              </span>
            </div>
            <Button
              class="cancel"
              v-if="canCancelEstimate(estimate)"
              @click="showEstimateCancelModel = true"
            >
              {{ $t("ESTIMATES.CANCEL_ESTIMATE") }}
            </Button>
            <Button
              class="next mt-2"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              @click="showEndComponentsEstimateModal = true"
              v-if="
                canEndComponentsEstimate(estimate) &&
                !canEndTimesEstimate(estimate)
              "
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.END_COMPONENTS_ESTIMATE") }}
            </Button>
            <Button
              class="next mt-2"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              @click="showEndEstimateModal = true"
              v-if="
                canEndComponentsEstimate(estimate) &&
                canEndTimesEstimate(estimate)
              "
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.END_ESTIMATE") }}
            </Button>
            <Button
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              class="next mt-2"
              @click="next"
              v-else
            >
              {{ $t("ESTIMATES.GO_TO_TIMES") }}
              <i class="fal fa-long-arrow-right"></i>
            </Button>
          </div>
          <div class="d-flex flex-column align-items-end">
            <div class="h5 m-0 text-nowrap">
              {{ $t("ESTIMATES.COMPONENTS") }}:
              {{ $formatCurrency(componentsTotal) }}
            </div>
            <div class="h5 m-0 text-nowrap">
              {{ $t("ESTIMATES.TIMES") }}: {{ $formatCurrency(worksTotal) }}
            </div>
            <div class="h2 text-nowrap">
              {{ $t("COMMON.TOTAL") }}: {{ $formatCurrency(total) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="active == TIMES_STEP"
        data-spy="scroll"
        data-target=".steps-content-footer"
        data-offset="0"
      >
        <div class="position-relative">
          <div
            class="estimate-overlay position-absolute w-100 h-100 top-0 p-6 z-10 d-flex flex-column align-items-center justify-content-center"
            v-if="otherWorkOnTimes"
          >
            <img
              class="mb-4"
              src="/img/estimation-in-progress.svg"
              alt="estimation in progress"
            />
            <span class="h1 text-darker">
              {{ $t("ESTIMATES.OTHER_WORK_ON_ESTIMATE") }}
            </span>
            <div
              class="inspection-form-group next-button justify-content-center"
            >
              <base-button
                class="btn btn-sm elite-submit"
                size="sm"
                type="button"
                @click="showTakeOverTimesModal = true"
                v-if="canTakeOverTimesEstimate(estimate)"
              >
                {{ $t("ESTIMATES.TAKE_OVER_ESTIMATE") }}
              </base-button>
            </div>
          </div>
          <div
            class="estimate-details-body"
            :class="{ 'blur-sm': otherWorkOnTimes }"
          >
            <div class="estimate-details-body-header">
              <div class="estimate-details-body-header-left">
                <h3>{{ $t("ESTIMATES.ESTIMATE_DETAIL") }}</h3>
              </div>
              <div class="estimate-details-body-header-right">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    {{ $t("ESTIMATES.ESTIMATE_ITEM_HOURLY_RATE") }}
                  </span>
                  <div>
                    <base-input
                      placeholder="0"
                      v-model.number="hourlyRate"
                      :disabled="!canEditTimes(estimate)"
                      class="clear-input-number-arrows"
                      append-icon="$"
                      input-classes="border-right-0 p-2 text-right"
                      input-group-classes="text-black border-2 purge-append-border input-group-text-p-2 w-90px"
                      min="0"
                      type="number"
                    >
                      <template #append
                        ><span class="text-black">$</span></template
                      >
                    </base-input>
                  </div>
                </div>
                <base-button
                  class="btn btn-sm text-nowrap"
                  type="button"
                  v-if="canGenerateInspectionReport"
                  @click="downloadInspectionReport"
                >
                  {{ $t("ESTIMATES.INSPECTION_REPORT") }}
                  <i class="far fa-file-pdf"></i>
                </base-button>
              </div>
            </div>
            <div class="custom-separator-bold"></div>
            <div class="estimate-details-body-content">
              <div class="list">
                <div class="view-form" ref="estimate_items_form">
                  <estimate-item-form
                    v-for="(item, index) in items"
                    :key="index"
                    :item.sync="item"
                    :can-edit-status="false"
                    :can-show-status="!!item.status"
                    :can-edit-components="
                      !itemIsApproved(item) && canEditComponents(estimate)
                    "
                    :can-edit-times="
                      !itemIsApproved(item) && canEditTimes(estimate)
                    "
                    :can-remove-item="
                      !itemIsApproved(item) &&
                      canEditTimes(estimate) &&
                      items.length > 1
                    "
                    :without-components="estimate.without_components"
                    :isLastItem="canEditTimes(estimate) && lastItemKey == index"
                    @update:item="
                      (itemModel) => itemChanged(index, itemModel, TIMES_STEP)
                    "
                    :show-component-details-required-error-message="
                      canShowComponentDetailsRequiredErrorMessage(item.id)
                    "
                    @openCommentModal="openCommentModal"
                    @deleteItem="deleteItem(index)"
                    @addNewItem="addItem(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="steps-content-footer">
          <div class="mr-auto align-content-center">
            <Button
              class="cancel"
              v-if="canCancelEstimate(estimate)"
              @click="showEstimateCancelModel = true"
            >
              {{ $t("ESTIMATES.CANCEL_ESTIMATE") }}
            </Button>
            <Button
              class="prev"
              @click="prev"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
            >
              <i class="fal fa-long-arrow-left"></i>
              {{ $t("ESTIMATES.BACK_TO_COMPONENTS") }}
            </Button>
            <!-- <Button
              class="next mt-2"
              @click="showStartTimesEstimateModal = true"
              v-if="canStartTimesEstimate"
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.START_ESTIMATION") }}
            </Button> -->
            <Button
              class="next mt-2"
              @click="showEndTimesEstimateModal = true"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              v-if="
                canEndTimesEstimate(estimate) &&
                !canEndComponentsEstimate(estimate) &&
                !emptyComponentDetailsItemsId.length
              "
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.END_TIMES_ESTIMATE") }}
            </Button>
            <Button
              class="next mt-2"
              @click="showEndEstimateModal = true"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              v-if="
                canEndTimesEstimate(estimate) &&
                canEndComponentsEstimate(estimate) &&
                !emptyComponentDetailsItemsId.length
              "
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.END_ESTIMATE") }}
            </Button>
            <a
              class="next mt-2 btn d-inline-flex align-items-center"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              :href="`#item-${emptyComponentDetailsItemsId[0] ?? null}`"
              @click="showComponentDetailsRequiredErrorMessage = true"
              style="font-weight: 400; letter-spacing: normal"
              v-if="
                canEndTimesEstimate(estimate) &&
                !canEndComponentsEstimate(estimate) &&
                emptyComponentDetailsItemsId.length
              "
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.END_TIMES_ESTIMATE") }}
            </a>
            <Button
              class="next mt-2"
              :disabled="!!unSaveItemsCount"
              :class="{ 'opacity-5 cursor-not-allowed': !!unSaveItemsCount }"
              @click="next"
              v-if="!canEndTimesEstimate(estimate)"
            >
              {{ $t("ESTIMATES.GO_TO_APPROVE") }}
              <i class="fal fa-long-arrow-right"></i>
            </Button>
          </div>
          <div class="d-flex flex-column align-items-end">
            <div class="h5 m-0 text-nowrap">
              {{ $t("ESTIMATES.COMPONENTS") }}:
              {{ $formatCurrency(componentsTotal) }}
            </div>
            <div class="h5 m-0 text-nowrap">
              {{ $t("ESTIMATES.TIMES") }}: {{ $formatCurrency(worksTotal) }}
            </div>
            <div class="h2 text-nowrap">
              {{ $t("COMMON.TOTAL") }}: {{ $formatCurrency(total) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="active == APPROVAL_STEP">
        <div class="position-relative">
          <div class="estimate-details-body">
            <div class="estimate-details-body-header">
              <div class="estimate-details-body-header-left">
                <h3>{{ $t("ESTIMATES.ESTIMATE_DETAIL") }}</h3>
              </div>
              <div class="estimate-details-body-header-right">
                <div class="radio-group">
                  <base-checkbox
                    v-model="approvedAll"
                    :disabled="!canApproveEstimate(estimate)"
                  >
                    {{ $t("ESTIMATES.APPROVED_ALL") }}
                  </base-checkbox>
                </div>
                <base-button
                  class="btn btn-sm text-nowrap"
                  type="button"
                  v-if="canGenerateInspectionReport"
                  @click="downloadInspectionReport"
                >
                  {{ $t("ESTIMATES.INSPECTION_REPORT") }}
                  <i class="far fa-file-pdf"></i>
                </base-button>
              </div>
            </div>
            <div class="custom-separator-bold"></div>
            <div class="estimate-details-body-content">
              <div class="list">
                <div class="view-form" ref="estimate_items_form">
                  <estimate-item-form
                    v-for="(item, index) in items"
                    :key="index"
                    :item="item"
                    :can-edit-status="canApproveEstimate(estimate)"
                    :can-show-status="true"
                    :can-edit-components="false"
                    :can-edit-times="false"
                    :can-remove-item="false"
                    :without-components="estimate.without_components"
                    can-show-review-alert
                    @update:item="
                      (itemModel) =>
                        itemChanged(index, itemModel, APPROVAL_STEP)
                    "
                    @openCommentModal="openCommentModal"
                    @deleteItem="deleteItem(index)"
                    @addNewItem="addItem(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="steps-content-footer">
          <div class="mr-auto align-content-center">
            <Button
              class="cancel"
              v-if="canCancelEstimate(estimate)"
              @click="showEstimateCancelModel = true"
            >
              {{ $t("ESTIMATES.CANCEL_ESTIMATE") }}
            </Button>
            <Button class="prev" @click="prev">
              <i class="fal fa-long-arrow-left"></i>
              {{ $t("ESTIMATES.BACK_TO_TIMES") }}
            </Button>
            <Button
              class="next mt-2"
              @click="showEndApproveEstimateModal = true"
              v-if="canEndApproveEstimate(estimate)"
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("ESTIMATES.END_APPROVE_ESTIMATE") }}
            </Button>
          </div>
          <div class="d-flex flex-column align-items-end">
            <div class="h5 m-0 text-nowrap">
              {{ $t("ESTIMATES.COMPONENTS") }}:
              {{ $formatCurrency(componentsTotal) }}
            </div>
            <div class="h5 m-0 text-nowrap">
              {{ $t("ESTIMATES.TIMES") }}: {{ $formatCurrency(worksTotal) }}
            </div>
            <div class="h2 text-nowrap">
              {{ $t("COMMON.TOTAL") }}: {{ $formatCurrency(total) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showMessageModal" modal-classes="message">
      <template slot="header">
        <h5 class="modal-title" id="inspection-message">
          {{ $t("INSPECTIONS.ADD_COMMENT") }}
        </h5>
      </template>
      <div>
        <base-input
          type="textarea"
          :label="$t('INSPECTIONS.COMMENT')"
          :placeholder="$t('INSPECTIONS.COMMENT')"
        >
          <textarea v-model="comment"></textarea>
        </base-input>
      </div>
      <template slot="footer">
        <base-button
          type="secondary"
          class="btn close"
          @click="showMessageModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button>
        <base-button type="primary" class="btn save">
          {{ $t("INSPECTIONS.ADD_COMMENT") }}
        </base-button>
      </template>
    </modal>

    <modal :show.sync="showPictureModal" modal-classes="picture">
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="images"
          @galleryChanged="
            (gallery_urls) => {
              images = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer">
        <base-button
          type="secondary"
          class="btn close"
          @click="showPictureModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button>
        <base-button type="primary" class="btn save" @click="addImages">
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>

    <inspection-confirmation-modal
      :confirm-action="cancelEstimate"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :loading="isLoading"
      :message="$t('ESTIMATES.CANCEL_THIS_ESTIMATE')"
      :open.sync="showEstimateCancelModel"
      modal-classes="modal-secondary cancel"
    />

    <inspection-confirmation-modal
      :confirm-action="startComponentsEstimate"
      :confirm-button-text="$t('COMMON.YES_START')"
      :loading="isLoading"
      :message="$t('ESTIMATES.START_THIS_COMPONENTS_ESTIMATE')"
      :open.sync="showStartComponentsEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="endComponentsEstimate"
      :confirm-button-text="$t('COMMON.YES_END')"
      :loading="isLoading"
      :message="
        estimate.without_components
          ? $t('ESTIMATES.END_THIS_COMPONENTS_ESTIMATE_WITHOUT_COMPONENTS')
          : $t('ESTIMATES.END_THIS_COMPONENTS_ESTIMATE')
      "
      :open.sync="showEndComponentsEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="endEstimate"
      :confirm-button-text="$t('COMMON.YES_END')"
      :loading="isLoading"
      :message="$t('ESTIMATES.END_THIS_ESTIMATE')"
      :open.sync="showEndEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="startTimesEstimate"
      :confirm-button-text="$t('COMMON.YES_START')"
      :loading="isLoading"
      :message="$t('ESTIMATES.START_THIS_TIMES_ESTIMATE')"
      :open.sync="showStartTimesEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="endTimesEstimate"
      :confirm-button-text="$t('COMMON.YES_END')"
      :loading="isLoading"
      :message="$t('ESTIMATES.END_THIS_TIMES_ESTIMATE')"
      :open.sync="showEndTimesEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="startApproveEstimate"
      :confirm-button-text="$t('COMMON.YES_START')"
      :loading="isLoading"
      :message="$t('ESTIMATES.START_THIS_APPROVE_ESTIMATE')"
      :open.sync="showStartApproveEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="endApproveEstimate"
      :confirm-button-text="$t('COMMON.YES_END')"
      :loading="isLoading"
      :message="$t('ESTIMATES.END_THIS_APPROVE_ESTIMATE')"
      :open.sync="showEndApproveEstimateModal"
      modal-classes="modal-secondary validate"
    />

    <inspection-confirmation-modal
      :confirm-action="takeOverComponentsEstimate"
      :confirm-button-text="$t('ESTIMATES.YES_TAKE_OVER')"
      :loading="loading"
      :message="
        $t('ESTIMATES.TAKE_OVER_THIS_ESTIMATE', {
          name: $objectDenomination(estimate.startedComponentsBy),
        })
      "
      :open.sync="showTakeOverComponentsModal"
      modal-classes="modal-secondary take-over start"
    />

    <inspection-confirmation-modal
      :confirm-action="takeOverTimesEstimate"
      :confirm-button-text="$t('ESTIMATES.YES_TAKE_OVER')"
      :loading="loading"
      :message="
        $t('ESTIMATES.TAKE_OVER_THIS_ESTIMATE', {
          name: $objectDenomination(estimate.startedTimesBy),
        })
      "
      :open.sync="showTakeOverTimesModal"
      modal-classes="modal-secondary take-over start"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep, isEqual } from "lodash";
import { Button, Option, Select, Step, Steps } from "element-ui";
import GallerySelector from "@/components/GallerySelector.vue";
import EstimateItemForm from "./EstimateItemForm.vue";
import formMixin from "@/mixins/form-mixin";
import {
  estimateItemConditionsOptionsDisplay,
  estimateItemStatusesOption,
} from "@/constants/estimateItems";
import defaultEstimationItem from "../defaultEstimationItem";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
  ESTIMATE_STATUS_IN_PROGRESS,
} from "@/constants/estimates";
import { ESTIMATE_ITEM_STATUS_APPROVED } from "@/constants/estimateItems";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import EstimateViewEstimateManagers from "./EstimateViewEstimateManagers.vue";
import {
  ESTIMATE_TYPES_STATUS_IN_PROGRESS,
  ESTIMATE_TYPES_STATUS_COMPLETED,
} from "@/constants/estimates";
import swal from "sweetalert2";
import alertLeaveMixin from "@/mixins/alert-leave-mixin";

export default {
  name: "estimate-view-estimate",

  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Steps.name]: Steps,
    [Step.name]: Step,
    GallerySelector,
    EstimateItemForm,
    InspectionConfirmationModal,
    EstimateViewEstimateManagers,
  },

  mixins: [
    alertLeaveMixin,
    formMixin,
    requestErrorMixin,
    estimatePermissionsMixin,
  ],

  props: {
    department: {
      type: String,
      default: null,
      description: "Department id",
    },
    estimateType: {
      type: String,
      default: null,
      description: "Estimate Type id",
    },
    assignTo: {
      type: String,
      default: null,
      description: "Assign id",
    },
    hourlyRateValue: {
      type: Number,
      default: "0",
    },
    estimateData: {
      type: Object,
      default: null,
      description: "Estimate data",
    },
    formErrors: {
      type: [Object, Array],
      default: null,
      description: "Form errors",
    },
    loading: {
      type: Boolean,
      default: false,
      description: "Loading",
    },
  },

  data() {
    let estimateData = { ...this.estimateData };
    estimateData = this.$fillUserOrganizationData(estimateData);

    const itemData = {
      relationshipNames: defaultEstimationItem.relationshipNames,
      organization: {
        ...defaultEstimationItem.organization,
        id: estimateData?.organization?.id,
      },
      estimation: {
        ...defaultEstimationItem.estimation,
        id: this.estimateData.id,
      },
    };

    let items = [];

    this.estimateData.items.forEach(
      (item) => (items = [...items, { ...item, ...itemData }])
    );

    let itemExample = cloneDeep({
      ...defaultEstimationItem,
      ...itemData,
      component_condition_editable: true,
      work_amount: this.hourlyRateValue,
    });

    if (items.length < 1) {
      items.push(cloneDeep(itemExample));
    }

    const COMPONENTS_STEP = 0;
    const TIMES_STEP = 1;
    const APPROVAL_STEP = 2;

    const withoutComponents = this.estimateData.without_components ?? false;

    return {
      alertLeave: true,
      commentSectionModalOpened: true,
      showPictureModal: false,
      showMessageModal: false,
      departmentModel: this.department,
      estimateTypeModel: this.estimateType,
      assignToModel: this.assignTo,
      hourlyRate: this.hourlyRateValue,
      estimate: estimateData,
      items,
      images: [],
      comment: String,
      conditionOptionsDisplay: estimateItemConditionsOptionsDisplay,
      statusOptions: estimateItemStatusesOption,
      itemExample,
      active: COMPONENTS_STEP,
      COMPONENTS_STEP,
      TIMES_STEP,
      APPROVAL_STEP,
      showEstimateCancelModel: false,
      showStartComponentsEstimateModal: false,
      showEndComponentsEstimateModal: false,
      showEndEstimateModal: false,
      showStartTimesEstimateModal: false,
      showEndTimesEstimateModal: false,
      showStartApproveEstimateModal: false,
      showEndApproveEstimateModal: false,
      withoutComponents,
      showTakeOverComponentsModal: false,
      showTakeOverTimesModal: false,
      showComponentDetailsRequiredErrorMessage: false,
    };
  },

  computed: {
    ...mapGetters({
      me: "profile/me",
    }),

    comments: function () {
      return [{ id: 2, type: "comments", comment: "comments", owner: this.me }];
    },
    subTotals: function () {
      return this.items.map((item) => {
        // let componentAmount = item.component_quantity * item.component_amount;
        let componentAmount = item.components.reduce(
          (acc, component) =>
            acc + component.component_quantity * component.component_amount,
          0
        );
        let workAmount = item.work_amount * item.repair_time_hour;

        return {
          componentAmount,
          workAmount,
          total: componentAmount + workAmount,
        };
      });
    },

    total: function () {
      return this.componentsTotal + this.worksTotal;
    },

    componentsTotal: function () {
      if (this.estimate.without_components) {
        return 0;
      }
      return this.subTotals.reduce(
        (acc, value) => acc + value.componentAmount,
        0
      );
    },

    worksTotal: function () {
      return this.subTotals.reduce((acc, value) => acc + value.workAmount, 0);
    },

    canEditStatus: function () {
      return (
        !!this.estimate &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_ESTIMATIONS) &&
        this.estimate.status == ESTIMATE_STATUS_IN_PROGRESS_APPROVE
      );
    },

    canGenerateInspectionReport: function () {
      return (
        !!this.estimate.inspection &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_INSPECTIONS)
      );
    },
    lastItemKey: function () {
      return Math.max(this.items.length - 1, 0);
    },

    otherWorkOnComponents: function () {
      return (
        this.componentsAreEditable(this.estimate) &&
        [
          ESTIMATE_TYPES_STATUS_IN_PROGRESS,
          ESTIMATE_TYPES_STATUS_COMPLETED,
        ].includes(this.estimate.components_status) &&
        !this.amEstimatingComponents(this.estimate)
      );
    },

    otherWorkOnTimes: function () {
      return (
        this.timesAreEditable(this.estimate) &&
        [
          ESTIMATE_TYPES_STATUS_IN_PROGRESS,
          ESTIMATE_TYPES_STATUS_COMPLETED,
        ].includes(this.estimate.times_status) &&
        !this.amEstimatingTimes(this.estimate)
      );
    },
    approvedAll: {
      get() {
        return !this.items.find(
          (item) => item.status != ESTIMATE_ITEM_STATUS_APPROVED
        );
      },
      set(check) {
        if (check) {
          const items = cloneDeep(this.items);
          for (const itemKey in items) {
            items.splice(itemKey, 1, {
              ...items[itemKey],
              status: ESTIMATE_ITEM_STATUS_APPROVED,
            });
          }
          this.items = items;
        } else {
          const items = cloneDeep(this.items);
          for (const itemKey in items) {
            items.splice(itemKey, 1, {
              ...items[itemKey],
              status: "",
            });
          }
          this.items = items;
        }
      },
    },
    canShowReviewAlert: function () {
      return [
        ESTIMATE_STATUS_IN_PROGRESS_APPROVE,
        ESTIMATE_STATUS_IN_PROGRESS,
      ].includes(this.inspection);
    },

    emptyComponentDetailsItemsId: function () {
      return [];
      return this.items
        .filter((item) => item.id && !item.component_details)
        .map((item) => item.id);
    },

    unSaveItemsCount: function () {
      return this.items.filter(
        (item) => !item.id || !item.name || !item.component_condition
      ).length;
    },
  },

  async created() {
    this.setActiveStep();
    // await this.$store.dispatch(
    //   "organizationOptions/getOrganizationConfig",
    //   this.estimateData.organization.id
    // );
    // this.hourlyRate =
    //   this.organizationConfig.DEFAULT_ESTIMATION_ITEM_HOURLY_RATE ?? 0;
  },

  methods: {
    prev() {
      this.active = Math.max(this.active - 1, this.COMPONENTS_STEP);
    },

    next() {
      const active = Math.min(this.active + 1, this.APPROVAL_STEP);

      this.active = active;
    },

    setActiveStep() {
      if (
        this.canStartApproveEstimate(this.estimate) ||
        this.canEndApproveEstimate(this.estimate)
      ) {
        this.active = this.APPROVAL_STEP;
      } else if (
        this.canEditTimes(this.estimate) &&
        ((this.isPending(this.estimate) &&
          !this.canEditComponents(this.estimate)) ||
          (!this.isPending(this.estimate) &&
            !this.canEndComponentsEstimate(this.estimate)))
      ) {
        this.active = this.TIMES_STEP;
      } else {
        this.active = this.COMPONENTS_STEP;
      }
    },

    addItem(index) {
      index++;
      let item = cloneDeep(this.itemExample);
      this.items = cloneDeep([
        ...this.items.slice(0, index),
        item,
        ...this.items.slice(index),
      ]);
      // this.items.push({
      //   ...item,
      //   organization: this.estimate?.organization,
      //   work_amount: this.hourlyRate,
      // });
      this.$notify({
        type: "success",
        timeout: 3000,
        message: this.$t("ESTIMATES.ESTIMATE_ITEM_ADDED"),
      });
    },

    addImages() {
      /* this.fieldModel.images = cloneDeep(this.images);
      this.showPictureModal = false;
      this.$emit("fieldChanged", cloneDeep(this.fieldModel)); */
    },

    addMessage() {
      /*  this.fieldModel.comment = this.comment;
      this.showMessageModal = false;
      this.$emit("fieldChanged", cloneDeep(this.fieldModel)); */
    },

    openCommentModal() {
      this.commentSectionModalOpened = true;
    },

    closeCommentModal() {
      this.commentSectionModalOpened = false;
    },

    deleteItem(index) {
      const newItems = cloneDeep(this.items).filter((_, idx) => idx != index);

      this.items = [];

      this.$nextTick(() => {
        this.items = newItems;
      });
    },

    startEstimate(step) {
      if (!this.isLoading) {
        switch (step) {
          case this.COMPONENTS_STEP:
            if (this.canStartComponentsEstimate(this.estimate)) {
              this.startComponentsEstimate();
            }
            if (this.canStartTimesEstimate(this.estimate)) {
              this.startTimesEstimate();
            }
            break;
          case this.TIMES_STEP:
            if (this.canStartTimesEstimate(this.estimate)) {
              this.startTimesEstimate();
            }
            if (this.canStartComponentsEstimate(this.estimate)) {
              this.startComponentsEstimate();
            }
            break;
          case this.APPROVAL_STEP:
            if (this.canStartApproveEstimate(this.estimate)) {
              this.startApproveEstimate();
            }
            break;
        }
      }
    },

    itemChanged(index, item, step) {
      this.startEstimate(step);
      this.items.splice(index, 1, item);
    },

    itemIsApproved(item) {
      return item?.status == ESTIMATE_ITEM_STATUS_APPROVED;
    },

    cancelEstimate() {
      this.$emit("onCancelEstimate", true);
    },

    startComponentsEstimate() {
      this.$emit("onStartComponentsEstimate", true);
    },

    endComponentsEstimate() {
      this.$emit("onEndComponentsEstimate", true);
    },

    endEstimate() {
      this.$emit("onEndEstimate", true);
    },

    startTimesEstimate() {
      this.$emit("onStartTimesEstimate", true);
    },

    endTimesEstimate() {
      this.$emit("onEndTimesEstimate", true);
    },

    startApproveEstimate() {
      this.$emit("onStartApproveEstimate", true);
    },

    endApproveEstimate() {
      this.$emit("onEndApproveEstimate", true);
    },

    takeOverComponentsEstimate() {
      this.$emit("onTakeOverComponentsEstimate", {
        estimationId: this.estimate.id,
        data: { user_id: this.me.id },
      });
    },

    onChangeComponentsEstimateManager(data) {
      this.$emit("onTakeOverComponentsEstimate", {
        estimationId: this.estimate.id,
        ...data,
      });
    },

    takeOverTimesEstimate() {
      this.$emit("onTakeOverTimesEstimate", {
        estimationId: this.estimate.id,
        data: { user_id: this.me.id },
      });
    },

    onChangeTimesEstimateManager(data) {
      this.$emit("onTakeOverTimesEstimate", {
        estimationId: this.estimate.id,
        ...data,
      });
    },

    switchToStep(step) {
      if (!this.unSaveItemsCount) {
        this.active = step;
      }
    },
    toggleWithoutComponents() {
      this.withoutComponents = !this.withoutComponents;
    },

    async downloadInspectionReport() {
      swal.fire({
        // title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "inspections/getReport",
          this.estimate.inspection.id
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.estimate.inspection.code}-report.pdf`
        );
        document.body.appendChild(link);
        link.click();
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    canShowComponentDetailsRequiredErrorMessage(id = null) {
      return (
        this.showComponentDetailsRequiredErrorMessage &&
        !!id &&
        this.emptyComponentDetailsItemsId.includes(id)
      );
    },
  },

  mounted() {},

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },

    // estimate: {
    //   handler: function () {
    //     const estimateData = cloneDeep(this.estimate);
    //     delete estimateData.items;

    //     this.$emit("onUpdateEstimate", estimateData);

    //     if (estimateData.without_components != this.estimateData.without_components) {
    //       this.startEstimate(this.COMPONENTS_STEP);
    //     }
    //   },
    //   deep: true,
    // },

    withoutComponents() {
      this.estimate.without_components = this.withoutComponents;

      const estimateData = cloneDeep(this.estimate);
      delete estimateData.items;

      this.$emit("onUpdateEstimate", estimateData);

      this.startEstimate(this.COMPONENTS_STEP);
    },

    estimateData(value) {
      if (!isEqual(this.estimate, value)) {
        this.estimate = cloneDeep(value);
      }
    },

    loading(value) {
      this.isLoading = value;
      if (!value) {
        this.showEstimateCancelModel = false;
        this.showStartComponentsEstimateModal = false;
        this.showEndComponentsEstimateModal = false;
        this.showEndEstimateModal = false;
        this.showStartTimesEstimateModal = false;
        this.showEndTimesEstimateModal = false;
        this.showStartApproveEstimateModal = false;
        this.showEndApproveEstimateModal = false;
      }
    },
    hourlyRate(value, oldValue) {
      const items = cloneDeep(this.items);
      for (const itemKey in items) {
        if (
          !this.$idExist(items[itemKey]?.id) ||
          items[itemKey].work_amount == oldValue
        ) {
          items.splice(itemKey, 1, {
            ...items[itemKey],
            work_amount: value,
          });
        }
      }
      this.itemExample = cloneDeep({ ...this.itemExample, work_amount: value });
      this.items = items;
    },
  },
};
</script>

<style>
.blur-sm {
  filter: blur(2px);
}
.z-10 {
  z-index: 10;
}
.form-control.bg-transparent {
  background-color: transparent !important;
}
.w-90px {
  width: 90px;
}
</style>
