import service from "@/store/services/organization-options-service";

function formatOptions(optionsList) {
  const formattedOptions = {};
  for (const option of optionsList) {
    formattedOptions[option.name] = option.value;
  }
  return formattedOptions;
}

export const state = () => ({
  organizationConfig: {},
});

export const mutations = {
  SET_RESOURCE: (state, organizationConfig) => {
    state.organizationConfig = organizationConfig;
  },
};

export const actions = {
  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then(({ list, meta }) => {
      commit("SET_RESOURCE", formatOptions(list));
    });
  },

  getOrganizationConfig({ commit, dispatch }, params) {
    let { id, allowedLocations } = params;
    if (!id) {
      id = params;
    }

    return service
      .getOrganizationConfig(id, allowedLocations, this.$axios)
      .then(({ list, meta }) => {
        commit("SET_RESOURCE", formatOptions(list));
      });
  },
};

const getters = {
  organizationConfig: (state) => state.organizationConfig,
};

const organizationConfig = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default organizationConfig;
