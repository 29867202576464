import moment from "moment";
import defaultVehicle from "@/views/Pages/VehiclesModule/VehicleManagement/defaultVehicle";
import defaultCustomer from "../../CrmModule/CustomerManagement/defaultCustomer";

export default {
  type: "inspections",
  excerpt: "",
  work_order: "",
  gallery: [],
  odometer: null,
  registration_number: null,
  relationshipNames: [
    "organization",
    "customer",
    "approver",
    // "creator",
    "request",
    "inspectionForm",
    "allowedLocations",
    "tags",
    "vehicle",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  customer: defaultCustomer,
  approver: {
    type: "users",
    id: null,
  },
  // creator: {
  //   type: "users",
  //   id: null,
  // },
  request: {
    type: "requests",
    id: null,
  },
  inspectionForm: {
    type: "inspection-forms",
    id: null,
  },
  vehicle: defaultVehicle,
  inspectionFields: [],
  allowedLocations: [],
  tags: [],
};
