<template>
  <modal :show="true" modal-classes="modal-secondary start">
    <template slot="header">
      <div class="header">
        <div class="header-left">
          <i class="fas fa-exclamation-triangle"></i>
          <span>Important</span>
        </div>
        <div class="header-right">
          <button @click="cancel">
            <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
    </template>
    <!--<div class="icon">
      <img src="/img/info-circle.svg" alt="icon" />
    </div> -->
    <p>{{ $t("INSPECTIONS.INSPECTION_HAS_UNTOUCHED") }}</p>
    <div class="list">
      <div class="list-item" v-for="field in untouchedFields" :key="field.id">
        <ul>
          <li>
            {{ findFieldSubSection(field.id).name }} :
            {{ field.name }}
          </li>
          <li>
            <a href="#" @click="openSection(findFieldSection(field.id))">
              {{ $t("INSPECTIONS.FILL_FIELD") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <template slot="footer">
      <Button class="no" @click="cancel">
        {{ $t("COMMON.CANCEL") }}
      </Button>
      <Button class="yes" @click="continueStep">
        {{ $t("COMMON.CONTINUE") }}
      </Button>
    </template>
  </modal>
</template>

<script>
import { Button } from "element-ui";
import { getUntouchedFields } from "@/helpers/formFieldHelper";

export default {
  name: "inspection-view-inspection-untouched-fields",

  components: {
    [Button.name]: Button,
  },

  props: ["inspection"],

  data() {
    return {
      untouchedFields: getUntouchedFields(this.inspection),
    };
  },

  computed: {},

  created() {},

  mounted() {
    this.untouchedFields = getUntouchedFields(this.inspection);
  },

  methods: {
    findFieldSection(formFieldId) {
      for (const section of Object.values(this.inspection.sectionsScores)) {
        for (const subSection of Object.values(section.subSections)) {
          for (const formField of Object.values(subSection.fields)) {
            if (formFieldId == formField.id) {
              return section;
            }
          }
        }
      }
    },

    findFieldSubSection(formFieldId) {
      for (const section of Object.values(this.inspection.sectionsScores)) {
        for (const subSection of Object.values(section.subSections)) {
          for (const formField of Object.values(subSection.fields)) {
            if (formFieldId == formField.id) {
              return subSection;
            }
          }
        }
      }
    },

    continueStep() {
      this.$emit("onOpenSection", this.untouchedFields[0].section);
    },

    openSection(section) {
      this.$emit("onOpenSection", section);
    },

    cancel() {
      this.$emit("onCloseModal");
    },
  },

  watch: {},
};
</script>
