export const INSPECTION_STATUS_DRAFT = "DRAFT";
export const INSPECTION_STATUS_PENDING = "PENDING";
export const INSPECTION_STATUS_APPROVED = "APPROVED";
export const INSPECTION_STATUS_ASSIGNED = "ASSIGNED";
export const INSPECTION_STATUS_COMPLETED = "COMPLETED";
export const INSPECTION_STATUS_CANCELED = "CANCELED";
export const INSPECTION_STATUS_IN_PROGRESS = "IN_PROGRESS";

export const INSPECTION_ACTION_TO_REPLACE = "TO_REPLACE";
export const INSPECTION_ACTION_TO_REPAIR = "TO_REPAIR";
export const INSPECTION_ACTION_TO_VERIFY = "TO_VERIFY";
export const INSPECTION_ACTION_TO_SCHEDULE = "TO_SCHEDULE";
export const INSPECTION_ACTION_TO_MONITOR = "TO_MONITOR";
export const INSPECTION_ACTION_NO_ACTION = "NO_ACTION";
export const INSPECTION_ACTION_SEEPAGE = "SEEPAGE";
export const INSPECTION_ACTION_VIBRATIONS = "VIBRATIONS";
export const INSPECTION_ACTION_TO_RECIFY = "TO_RECIFY";
export const INSPECTION_ACTION_RECOMMENDED_MAINTENANCE =
  "RECOMMENDED_MAINTENANCE";

export const inspectionStatusesOption = [
  INSPECTION_STATUS_DRAFT,
  // INSPECTION_STATUS_APPROVED,
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_COMPLETED,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_IN_PROGRESS,
];
