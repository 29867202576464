import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/inspection-fields`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/inspection-fields/${id}?include=organization,inspectionFormSectionField&add_data=inspectionSectionScores`,
      options
    )
    .then((response) => {
      let inspectionField = jsona.deserialize(response.data);
      delete inspectionField.links;
      return inspectionField;
    });
}

function add(inspectionField) {
  const payload = jsona.serialize({
    stuff: inspectionField,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/inspection-fields?add_data=inspectionSectionScores`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(inspectionField) {
  const payload = jsona.serialize({
    stuff: inspectionField,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const doNotAddData = inspectionField.doNotAddData || false;

  return axios
    .patch(
      `${url}/inspection-fields/${inspectionField.id}?${doNotAddData ? '' : 'add_data=inspectionSectionScores'}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/inspection-fields/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
