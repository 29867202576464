<template>
  <div class="elite-tabs-wrapper-content">
    <repair-ref-standardized :repair="repair" :title="title">
      <template #buttons>
        <button class="edit" @click="showEstimation" v-if="canShowEstimation">
          <span> {{ $t("INSPECTIONS.SHOW_ESTIMATION") }} </span>
          <i class="fa-light fa-eye"></i>
        </button>
      </template>
    </repair-ref-standardized>
    <estimate-view-global
      class-name=""
      :estimate="repair.estimation"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import RepairRefStandardized from "@/components/RepairRefStandardized.vue";
import EstimateViewGlobal from "../../EstimateManagement/partials/EstimateViewGlobal.vue";

export default {
  name: "repair-view-estimate",

  components: { EstimateViewGlobal, RepairRefStandardized },

  props: ["repair"],

  data() {
    return {};
  },

  computed: {
    canShowEstimation: function () {
      return (
        !!this.repair.estimation &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_ESTIMATIONS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS)
      );
    },
    title: function () {
      return `${this.repair.code} - ${this.$t("COMMON.ESTIMATION")}`;
    },
  },

  created() {},

  methods: {
    showEstimation() {
      this.$router.push(this.$objectViewRoute(this.repair.estimation));
    },
  },

  mounted() {},

  watch: {},
};
</script>
