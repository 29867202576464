export default {
  ESTIMATES_LIST: "Estimate List",
  ADD_ESTIMATE: "Add Estimate",
  VIEW_ESTIMATE: "View Estimate",
  EDIT_ESTIMATE: "Edit Estimate",
  ESTIMATE_ADDED: "Estimate Added",
  ESTIMATE_UPDATED: "Estimate Updated",
  DELETE_THIS_ESTIMATE: "Delete this Estimate?",
  ESTIMATE_DELETED: "Estimate Deleted",
  ESTIMATE_STOCK: "Stock",
  ESTIMATE_TYPE: "Type",
  CREATOR: "Creator",
  ALL: "All",
  APPROVED_ALL: "Approve All",
  ESTIMATE_STATUS_DRAFT: "Draft",
  ESTIMATE_STATUS_CANCELED: "Canceled",
  ESTIMATE_STATUS_COMPLETED: "Completed",
  ESTIMATE_STATUS_ASSIGNED: "Assigned",
  ESTIMATE_STATUS_APPROVED: "Approved",
  ESTIMATE_STATUS_REVIEWED: "Reviewed",
  ESTIMATE_STATUS_DENIED: "Denied",
  ESTIMATE_STATUS_PENDING_COMPONENTS: "Pending Parts",
  ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS: "Parts in Progress",
  ESTIMATE_STATUS_PENDING_TIMES: "Pending Times",
  ESTIMATE_STATUS_IN_PROGRESS_TIMES: "Times in Progress",
  ESTIMATE_STATUS_PENDING_APPROVE: "Pending Approval",
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE: "Approval in Progress",
  ESTIMATE_STATUS_PENDING: "Pending",
  ESTIMATE_STATUS_IN_PROGRESS: "In Progress",
  ESTIMATE_TYPES_STATUS_PENDING: "Pending ",
  ESTIMATE_TYPES_STATUS_IN_PROGRESS: "In Progress ",
  ESTIMATE_TYPES_STATUS_COMPLETED: "Completed ",
  APPROVED_AT: "Approved At",
  APPROVED_BY: "Approved By",
  ASSIGNED_BY: "Assigned By",
  CANCELED_BY: "Canceled By",
  CANCELED_AT: "Canceled At",
  COMPLETED_BY: "Completed By",
  ASSIGNED_TO: "Assigned To",
  ASSIGNED_AT: "Assigned At",
  REVIEWED_BY: "Reviewed By",
  REVIEWED_AT: "Reviewed At",
  DENIED_BY: "Denied By",
  DENIED_AT: "Denied At",
  ESTIMATE_CLIENT: "Client",
  ASSIGNED_TO_ME: "Assigned to me",
  CREATED_BY_ME: "Created by me",
  COMPLETED_AT: "Completed At",
  ESTIMATE_CLIENT: "Client",
  ESTIMATE_DEPARTMENT: "Department",
  ESTIMATE_ALL_DEPARTMENTS: "All Departments",
  ESTIMATE_ASSIGN_TO: "Assign To",
  ESTIMATE_TYPE: "Estimate Type",
  ESTIMATE_DETAIL: "Estimate Detail",
  ESTIMATE_ITEM: "Item",
  ESTIMATE_ITEM_DETAIL: "Item Detail",
  ESTIMATE_ITEM_STATE: "Item State",
  ESTIMATE_ITEM_PART: "Part",
  ESTIMATE_ITEM_PART_NUMBER: "Part Number",
  ESTIMATE_ITEM_MANUAL_LABOR: "Labor",
  ESTIMATE_ITEM_TIME: "Time",
  ESTIMATE_ITEM_HOURLY_RATE: "Hourly Rate",
  ESTIMATE_ITEM_APPROVED: "Approve",
  ESTIMATE_ITEM_REVIEW: "Review",
  ESTIMATE_ITEM_REFUSED: "Refuse",
  ESTIMATE_ITEM_STATUS_APPROVED: "Approved",
  ESTIMATE_ITEM_STATUS_REVIEWED: "Reviewed",
  ESTIMATE_ITEM_STATUS_DENIED: "Denied",
  ESTIMATE_ITEM_CONDITION_TO_REPLACE: "To Replace",
  ADD_ESTIMATE_ITEM_: "Add Item",
  TO_REPLACE: "To Replace",
  ESTIMATE_ITEM_CONDITION_TO_REPAIR: "To Repair",
  TO_REPAIR: "To Repair",
  ESTIMATE_ITEM_CONDITION_TO_VERIFY: "To Verify",
  TO_VERIFY: "To Verify",
  ESTIMATE_ITEM_CONDITION_TO_MONITOR: "To Monitor",
  TO_MONITOR: "To Monitor",
  ESTIMATE_ITEM_CONDITION_TO_SCHEDULE: "To Schedule",
  TO_SCHEDULE: "To Schedule",
  VEHICLE_VIN: "Vehicle VIN",
  SAVE_AND_SENT: "Save and Send",
  REMOVE_ITEM: "Remove Item",
  ESTIMATE_ITEM_ADDED: "Item Added",
  DELETE_THIS_ESTIMATE_ITEM: "Delete this item from estimate?",
  DELETE_THIS_ITEM_FROM_ESTIMATE:
    "Do you want to delete this item from the estimate?",
  ESTIMATE_ITEM_DELETED: "Item Deleted",
  APPROVE_ESTIMATE: "Approve Estimate",
  ESTIMATE_ASSIGNED: "Estimate Assigned",
  ESTIMATE_APPROVED: "Estimate Approved",
  ASSIGN_ESTIMATE: "Assign Estimate",
  ESTIMATE_CANCELED: "Estimate Canceled",
  CANCEL_ESTIMATE: "Cancel Estimate",
  CANCEL_THIS_ESTIMATE: "Do you want to cancel this estimate?",
  ESTIMATE_DENIED: "Estimate Denied",
  DENY_ESTIMATE: "Deny Estimate",
  ESTIMATE_COMPLETED: "Estimate Completed",
  COMPLETE_ESTIMATE: "Complete Estimate",
  ESTIMATE_REVIEWED: "Estimate Reviewed",
  REVIEW_ESTIMATE: "Review Estimate",
  NEXT_STEP: "Next Step",
  SAVE_VEHICLE: "Save Vehicle",

  START_COMPONENTS_ESTIMATE: "Start editing estimate parts",
  START_THIS_COMPONENTS_ESTIMATE:
    "Do you want to start editing the parts estimate?",
  ESTIMATE_STARTED_COMPONENTS: "Editing parts estimate started",
  STARTED_COMPONENTS_AT: "Parts editing started on",
  STARTED_COMPONENTS_BY: "Parts editing started by",

  END_COMPONENTS_ESTIMATE: "Finish parts estimate",
  END_THIS_COMPONENTS_ESTIMATE: "Do you want to finish the parts estimate?",
  END_THIS_COMPONENTS_ESTIMATE_WITHOUT_COMPONENTS:
    "Do you want to finish the estimate without parts?",
  ESTIMATE_ENDED_COMPONENTS: "Parts estimate finished",
  ENDED_COMPONENTS_AT: "Parts editing finished on",
  ENDED_COMPONENTS_BY: "Parts editing finished by",

  START_TIMES_ESTIMATE: "Start editing estimate times",
  START_THIS_TIMES_ESTIMATE: "Do you want to start editing the times estimate?",
  ESTIMATE_STARTED_TIMES: "Editing times estimate started",
  STARTED_TIMES_AT: "Times editing started on",
  STARTED_TIMES_BY: "Times editing started by",

  END_TIMES_ESTIMATE: "Finish labor estimate",
  END_THIS_TIMES_ESTIMATE: "Do you want to finish the times estimate?",
  ESTIMATE_ENDED_TIMES: "Times estimate finished",
  ENDED_TIMES_AT: "Times editing finished on",
  ENDED_TIMES_AT: "Times editing finished by",

  END_ESTIMATE: "Finish estimate",
  END_THIS_ESTIMATE: "Do you want to finish the labor and parts estimate?",
  ESTIMATE_ENDED: "Estimate finished",

  START_APPROVE_ESTIMATE: "Start estimate approval",
  START_THIS_APPROVE_ESTIMATE: "Do you want to start approving the estimates?",
  ESTIMATE_STARTED_APPROVE: "Estimate approval started",
  STARTED_APPROVE_AT: "Approval started on",
  STARTED_APPROVE_BY: "Approval started by",

  END_APPROVE_ESTIMATE: "Finish approval",
  END_THIS_APPROVE_ESTIMATE: "Do you want to finish approving the estimates?",
  ESTIMATE_ENDED_APPROVE: "Estimate approval finished",
  ENDED_APPROVE_AT: "Approval finished on",
  ENDED_APPROVE_BY: "Approval finished by",

  VALID_ESTIMATE: "Validate Estimate",
  ESTIMATE_VALIDATED: "Estimate Validated",
  VALIDATED_AT: "Validated At",
  VALIDATED_BY: "Validated By",
  VALIDATE_THIS_ESTIMATE: "Do you want to validate this estimate?",

  TIMES: "Labor",
  COMPONENTS: "Parts",
  TIMES_STATUS: "Labor Status",
  COMPONENTS_STATUS: "Parts Status",

  ESTIMATE_IN_PROGRESS_WARNING: "Estimate is currently being modified",

  COMPONENTS_ESTIMATOR: "Parts Estimator",
  TIMES_ESTIMATOR: "Labor Estimator",

  START_ESTIMATION: "Start Estimation",
  END_ESTIMATION: "End Estimation",

  ESTIMATE_WITHOUT_COMPONENTS: "Estimate without parts",
  ESTIMATE_WITHOUT_TIMES: "Estimate without labor",
  NO_COMPONENTS_ADDED: "No parts have been added to this estimate",
  OTHER_WORK_ON_ESTIMATE: "Another user is currently working on this estimate",

  GO_TO_TIMES: "Go to labor",

  BACK_TO_COMPONENTS: "Go back to parts",
  GO_TO_APPROVE: "Go to approval",

  BACK_TO_TIMES: "Go back to labor",
  CODE: "Estimate",

  MECHANICS: "Mechanics",
  OFFICIAL_MECHANIC: "Official Mechanic",
  CREATED_AT: "Created At",
  CANCELED: "Canceled",
  UPDATED_AT: "Updated At",
  COMPLETED: "Completed",

  TAKE_OVER_ESTIMATE: "Take Over Estimate",
  TAKE_OVER_THIS_ESTIMATE:
    "This estimate is currently being worked on by {name}. \n Do you want to take it over?",
  YES_TAKE_OVER: "Yes, take over the estimate",
  COMPONENTS_TOOK_OVER: "Parts takeover completed",
  TIMES_TOOK_OVER: "Labor takeover completed",

  CHANGE_COMPONENTS_MANAGER: "Change parts manager",
  CHANGE_TIMES_MANAGER: "Change labor manager",

  SHOW_ESTIMATE: "View Estimate",
  ESTIMATE_ALREADY_EXIST: "An estimate is already in progress for this vehicle",

  REVIEW_ALERT: "The estimate will be set back to **in progress** for review",

  INSPECTION_REPORT: "Inspection Report",

  ASSIGN_APPROB: "Assign/Approve",
};
