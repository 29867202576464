import service from "@/store/services/users-service";
import router from "@/router";

import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  isAuthenticated: !!localStorage.getItem(
    "vue-authenticate.vueauth_access_token"
  ),
  list: {},
  user: {},
  meta: {},
  url: null,
});

export const mutations = {
  isAuthenticated(state, value) {
    state.isAuthenticated = value;
  },

  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, user) => {
    state.user = user;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((user) => {
      commit("SET_RESOURCE", user);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((user) => {
      commit("SET_RESOURCE", user);
    });
  },

  async code_verification({ commit, dispatch }, params) {
    await service.code_verification(params, this.$axios).then((response) => {
      localStorage.setItem(
        "vue-authenticate.vueauth_access_token",
        response?.access_token
      );
      commit("isAuthenticated", {
        isAuthenticated: true,
      });
      window.location.href = "/dashboard";
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((user) => {
      commit("SET_RESOURCE", user);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  activate({ commit, dispatch }, params) {
    return service.activate(params, this.$axios).then((user) => {
      commit("SET_RESOURCE", user);
    });
  },

  deactivate({ commit, dispatch }, params) {
    return service.deactivate(params, this.$axios).then((user) => {
      commit("SET_RESOURCE", user);
    });
  },

  invite({ commit, dispatch }, params) {
    return service.invite(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  user: (state) => state.user,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((user) => ({
      id: user.id,
      name: `${user.firstname} ${user.lastname}`,
    }));
  },
};

const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default users;
