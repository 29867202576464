<template>
  <modal :showClose="false" :show="showActionModal" modal-classes="message">
    <template slot="header">
      <h5 class="modal-title" id="inspection-message">
        {{ $t("INSPECTIONS.DEFINE_ACTION") }}
      </h5>
    </template>
    <div
      class="modal-radio-group"
      v-if="['WARNING', 'BAD'].includes(fieldValue)"
    >
      <div
        class="radio-group"
        v-for="act in getActions()"
        :key="`${act}_${refKey}`"
      >
        <div class="btn-group-item">
          <input
            type="radio"
            class="btn-check"
            :id="`action_${act}_${refKey}`"
            :name="`field_${act}_${refKey}`"
            :disabled="disabled"
            :value="act"
            v-model="action"
          />
          <label
            class="btn btn-outline-primary"
            :for="`action_${act}_${refKey}`"
          >
            {{ $t(`INSPECTIONS.INSPECTION_ACTION_${act}`) }}
            <span class="check">
              <i class="far fa-check"></i>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div>
      <base-input
        type="textarea"
        :label="$t('INSPECTIONS.COMMENT')"
        :placeholder="$t('INSPECTIONS.COMMENT')"
      >
        <textarea v-model="comment" :disabled="disabled" rows="5"></textarea>
      </base-input>
    </div>
    <template slot="footer">
      <base-button type="secondary" class="btn close" @click="cancelAction">
        {{ $t("COMMON.CANCEL") }}
      </base-button>

      <base-button
        v-if="!disabled"
        :disabled="!isActionValid()"
        type="primary"
        class="btn save"
        @click="addAction"
      >
        {{ $t("INSPECTIONS.DEFINE_ACTION") }}
      </base-button>
    </template>
  </modal>
</template>

<script>
import { uniqueId } from "lodash";
import {
  INSPECTION_ACTION_TO_REPLACE,
  INSPECTION_ACTION_TO_REPAIR,
  INSPECTION_ACTION_TO_VERIFY,
  INSPECTION_ACTION_TO_SCHEDULE,
  INSPECTION_ACTION_TO_MONITOR,
  INSPECTION_ACTION_NO_ACTION,
} from "@/constants/inspections";

import { Button } from "element-ui";

export default {
  name: "action-comment-value",

  components: {
    [Button.name]: Button,
  },

  props: [
    "disabled",
    "fieldValue",
    "actionValue",
    "commentValue",
    "showActionModal",
  ],

  data() {
    const refKey = uniqueId([]);
    return {
      action: this.actionValue,
      comment: this.commentValue,
      refKey: refKey,
      INSPECTION_ACTION_NO_ACTION: INSPECTION_ACTION_NO_ACTION,
    };
  },

  computed: {},

  created() {},

  methods: {
    addAction() {
      this.$emit("actionChanged", this.action, this.comment);
    },

    cancelAction() {
      this.$emit("actionCanceled");
    },

    getActions() {
      if (this.fieldValue == "BAD") {
        return [INSPECTION_ACTION_TO_REPLACE, INSPECTION_ACTION_TO_REPAIR];
      }
      if (this.fieldValue == "WARNING") {
        return [
          // INSPECTION_ACTION_TO_VERIFY,
          INSPECTION_ACTION_TO_SCHEDULE,
          INSPECTION_ACTION_TO_MONITOR,
        ];
      }
      return [INSPECTION_ACTION_NO_ACTION];
    },

    isActionValid() {
      return this.getActions().includes(this.action);
    },
  },

  mounted() {},

  watch: {
    actionValue() {
      this.action = this.actionValue;
    },
    commentValue() {
      this.comment = this.commentValue;
    },
    showActionModal() {
      this.action = this.actionValue;
      this.comment = this.commentValue;
    },
  },
};
</script>
