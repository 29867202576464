<template>
  <div>
    <vehicle-form
      :disable-location="disableLocation"
      :disable="disabled"
      :formErrors="formErrorsValue"
      :loading="loading"
      :location-options="currentUserRestrictLocations"
      :location="locationId"
      :organization="globalOrganization?.id"
      :saveText="vehicleSaveBtn"
      :vehicleData="estimate.vehicle"
      @formChanged="vehicleChanged"
      @vehicleHasInProgressEstimations="vehicleHasInProgressEstimations"
      @vehicleSubmitted="handleVehicleFormSubmit"
    >
      <template #submit-button><span></span></template>
    </vehicle-form>

    <div class="inspection-form-divider"></div>

    <div class="inspection-form-title">
      <h3>{{ $t("COMMON.WORK_ORDER") }}</h3>
    </div>

    <div class="inspection-form-divider" />

    <div>
      <div class="d-md-flex align-items-center">
        <h5 class="mr-3 mb-0">{{ $t("COMMON.ENTER_WORK_ORDER") }}:</h5>

        <base-input
          class="flex-grow-1"
          :disabled="disabled"
          v-model="estimate.work_order"
          :placeholder="$t('COMMON.WORK_ORDER')"
        />
      </div>
      <validation-error :errors="apiValidationErrors.work_order" />
    </div>

    <inspection-confirmation-modal
      :confirm-action="showOtherExistingEstimation"
      :cancel-action="closeModal"
      :confirm-button-text="$t('ESTIMATES.SHOW_ESTIMATE')"
      :cancel-button-text="$t('COMMON.OK')"
      :loading="loading"
      :message="$t('ESTIMATES.ESTIMATE_ALREADY_EXIST')"
      :open.sync="showVehicleEstimationExistAlertModal"
      modal-classes="modal-secondary validate"
    />
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import VehicleForm from "@/views/Pages/VehiclesModule/VehicleManagement/partials/VehicleForm.vue";
import formMixin from "@/mixins/form-mixin";
import defaultVehicle from "@/views/Pages/VehiclesModule/VehicleManagement/defaultVehicle";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import locationMixin from "@/mixins/location-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "estimate-form-vehicle-step",

  components: { VehicleForm, InspectionConfirmationModal, ValidationError },

  mixins: [formMixin, locationMixin],

  props: {
    estimateData: { type: Object },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const estimate = {
      ...this.estimateData,
      vehicle: {
        ...defaultVehicle,
        ...this.estimateData.vehicle,
        organization: {
          ...this.estimateData.vehicle?.organization,
          ...this.estimateData.organization,
        },
      },
    };
    const formErrorsValue = cloneDeep(this.formErrors);
    return {
      estimate,
      loading: false,
      formErrorsValue,
      existOtherEstimationId: null,
      showVehicleEstimationExistAlertModal: false,
    };
  },

  computed: {
    vehicleSaveBtn() {
      if (!this.estimate.vehicle?.id || isNaN(this.estimate.vehicle.id)) {
        return this.$t("VEHICLES.ADD_VEHICLE");
      } else {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      }
    },
    disableLocation() {
      return (
        !!this.estimate.id ||
        this.isRestrictToOneLocation ||
        !!this.globalLocation?.id
      );
    },
    locationId() {
      if (this.estimate?.id) {
        if (this.estimate?.allowedLocations?.length) {
          return this.estimate.allowedLocations[0]?.id;
        }

        return null;
      }

      return this.globalLocation?.id;
    },
  },

  methods: {
    async handleVehicleFormSubmit(vehicleData) {
      this.loading = true;
      vehicleData = { ...this.estimate.vehicle, ...vehicleData };
      let vehicle = null;
      try {
        if (this.$idExist(vehicleData?.id)) {
          delete vehicleData.organization;
          await this.$store.dispatch("vehicles/update", vehicleData);
          vehicle = await this.$store.getters["vehicles/vehicle"];
        } else {
          delete vehicleData.id;
          await this.$store.dispatch("vehicles/add", vehicleData);
          vehicle = await this.$store.getters["vehicles/vehicle"];
        }

        this.vehicleChanged(vehicle);

        this.estimate.gallery = vehicle?.gallery;
        this.estimate.odometer = vehicle?.odometer;
        this.estimate.odometer_unit = vehicle?.odometer_unit;
        this.estimate.registration_number = vehicle?.registration_number;
        this.estimate.color = vehicle?.color;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrorsValue = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    vehicleChanged(vehicleData) {
      this.estimate.vehicle = { ...this.estimate.vehicle, ...vehicleData };
      this.estimate.organization = {
        ...this.estimate.organization,
        ...vehicleData.organization,
      };
      this.estimate.allowedLocations = cloneDeep(vehicleData?.allowedLocations);
    },

    vehicleHasInProgressEstimations(estimationIds) {
      if (!!estimationIds && typeof estimationIds == "object") {
        if (this.$idExist(this.estimate.id)) {
          delete estimationIds[this.estimate.id];
        }

        if (Object.keys(estimationIds).length > 0) {
          this.existOtherEstimationId = Object.keys(estimationIds)[0];

          if (this.existOtherEstimationId) {
            this.showVehicleEstimationExistAlertModal = true;
          }
        }
      }
    },
    showOtherExistingEstimation() {
      return this.$emit("onViewEstimate", {
        id: this.existOtherEstimationId,
        type: "estimations",
      });
    },

    closeModal() {
      this.$emit("onCloseEstimateModal");
    },
  },

  watch: {
    estimate: {
      handler: function () {
        this.$emit("update:estimateData", this.estimate);
      },
      deep: true,
    },

    estimateData: {
      handler: function (value) {
        if (_.isEqual(value, this.estimate)) return;
        this.estimate = { ...this.estimate, ...value };
      },
      deep: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
      this.formErrorsValue = cloneDeep(errors);
    },
  },
};
</script>

<style lang="scss" scoped></style>
