var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-inner",attrs:{"id":`field_${_vm.fieldData.id}`}},[_c('div',{staticClass:"item-inner-text"},[_c('p',[_vm._v(_vm._s(_vm.fieldData.name))])]),_c('div',{staticClass:"item-inner-right"},[(_vm.loading)?_c('div',{staticClass:"custom-loading"},[_c('div',{staticClass:"custom-loading-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.LOADING")))])]):_vm._e(),_c('base-button',{class:{
        'is-filled': _vm.fieldData.images.length > 0,
      },attrs:{"disabled":!_vm.fieldData.can_upload_image,"icon":"","size":"sm"},on:{"click":function($event){_vm.showPictureModal = true}}},[_c('i',{staticClass:"far fa-camera"})]),_c('base-button',{class:{ 'is-filled': !!_vm.fieldData.comment },attrs:{"disabled":!_vm.fieldData.can_add_comment,"icon":"","size":"sm"},on:{"click":function($event){_vm.showActionModal = true}}},[(!_vm.fieldData.comment)?_c('i',{staticClass:"far fa-comment"}):_vm._e(),(!!_vm.fieldData.comment)?_c('i',{staticClass:"far fa-comment"}):_vm._e()]),_c('field-widget',{attrs:{"fieldForm":_vm.fieldData,"defaultValue":_vm.fieldData.value,"defaultActionValue":_vm.fieldData.action,"disabled":_vm.disabled,"commentValue":_vm.fieldData.comment,"touched":_vm.fieldData.touched},on:{"change":_vm.valueChanged,"actionChanged":_vm.actionChanged}})],1),_c('action-comment-widget',{attrs:{"showActionModal":_vm.showActionModal,"disabled":_vm.disabled,"actionValue":_vm.fieldData.action,"commentValue":_vm.fieldData.comment,"fieldValue":_vm.getValue()},on:{"actionCanceled":_vm.cancelAction,"actionChanged":_vm.changeAction}}),(
      _vm.fieldData.field_type == _vm.INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET
    )?_c('div',{staticClass:"battery-status-wrapper"},[_c('base-input',{attrs:{"value":_vm.fieldData.value?.charge,"disabled":true}}),_c('div',{staticClass:"form-group-separator",attrs:{"disabled":true}},[_vm._v("/")]),_c('base-input',{attrs:{"value":_vm.fieldData.value?.total_charge,"disabled":true}}),_c('base-input',{staticClass:"units",attrs:{"placeholder":_vm.$t('INSPECTIONS.UNIT'),"disabled":true}},[_c('el-select',{attrs:{"value":_vm.fieldData.value?.unit,"placeholder":_vm.$t('INSPECTIONS.UNIT'),"disabled":true}},[_c('el-option',{attrs:{"value":"AMPS"}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.AMPS"))+" ")]),_c('el-option',{attrs:{"value":"VOLTS"}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.VOLTS"))+" ")])],1)],1)],1):_vm._e(),_c('modal',{attrs:{"showClose":true,"show":_vm.showPictureModal,"modal-classes":"picture"},on:{"update:show":function($event){_vm.showPictureModal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"inspection-picture"}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.ADD_PICTURES"))+" ")])]),_c('div',[_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.PICTURES'),"defaultGallery":_vm.images,"ressource_name":"inspection_form_fields","ressource_id":_vm.fieldData.id ? _vm.fieldData.id : 0,"field":_vm.fieldData.name,"disabled":_vm.disabled},on:{"galleryChanged":(gallery_urls) => {
            _vm.images = gallery_urls;
          }}})],1),_c('template',{slot:"footer"},[(!_vm.disabled)?_c('base-button',{staticClass:"btn save",attrs:{"type":"primary"},on:{"click":_vm.addImages}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.SAVE"))+" ")]):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }