export const ESTIMATE_ITEM_STATUS_APPROVED = "APPROVED";
export const ESTIMATE_ITEM_STATUS_DENIED = "DENIED";
export const ESTIMATE_ITEM_STATUS_REVIEWED = "REVIEWED";

export const ESTIMATE_ITEM_CONDITION_TO_REPLACE = "TO_REPLACE";
export const ESTIMATE_ITEM_CONDITION_TO_REPAIR = "TO_REPAIR";
export const ESTIMATE_ITEM_CONDITION_TO_MONITOR = "TO_MONITOR";
export const ESTIMATE_ITEM_CONDITION_TO_VERIFY = "TO_VERIFY";
export const ESTIMATE_ITEM_CONDITION_TO_SCHEDULE = "TO_SCHEDULE";

export const estimateItemStatusesOption = {
  ESTIMATE_ITEM_STATUS_APPROVED,
  ESTIMATE_ITEM_STATUS_DENIED,
  ESTIMATE_ITEM_STATUS_REVIEWED,
};

export const estimateItemConditionsOption = {
  ESTIMATE_ITEM_CONDITION_TO_REPLACE,
  ESTIMATE_ITEM_CONDITION_TO_REPAIR,
  ESTIMATE_ITEM_CONDITION_TO_MONITOR,
  // ESTIMATE_ITEM_CONDITION_TO_VERIFY,
  ESTIMATE_ITEM_CONDITION_TO_SCHEDULE,
};

export const estimateItemConditionsOptionsDisplay = {
  [ESTIMATE_ITEM_CONDITION_TO_REPLACE]: {
    iconName: "fa-times-circle",
    color: "red",
    value: ESTIMATE_ITEM_CONDITION_TO_REPLACE,
  },
  [ESTIMATE_ITEM_CONDITION_TO_REPAIR]: {
    iconName: "fa-times-circle",
    color: "red",
    value: ESTIMATE_ITEM_CONDITION_TO_REPAIR,
  },
  [ESTIMATE_ITEM_CONDITION_TO_MONITOR]: {
    iconName: "fa-exclamation-triangle",
    color: "orange",
    value: ESTIMATE_ITEM_CONDITION_TO_MONITOR,
  },
  // [ESTIMATE_ITEM_CONDITION_TO_VERIFY]: {
  //   iconName: "fa-exclamation-triangle",
  //   color: "orange",
  //   value: ESTIMATE_ITEM_CONDITION_TO_VERIFY,
  // },
  [ESTIMATE_ITEM_CONDITION_TO_SCHEDULE]: {
    iconName: "fa-exclamation-triangle",
    color: "orange",
    value: ESTIMATE_ITEM_CONDITION_TO_SCHEDULE,
  },
};
