var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper full"},[_c('image-selector',{attrs:{"defaultImage":_vm.user.profile_image,"ressource_name":"users","ressource_id":_vm.user.id ? _vm.user.id : 0,"field":"profile_image"},on:{"imageChanged":(file_url) => {
          _vm.user.profile_image = file_url;
          _vm.onFormChanged();
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}})],1),(_vm.showIsStaff && _vm.$currentUser().is_staff)?_c('div',{staticClass:"form-wrapper full"},[_c('base-checkbox',{on:{"change":() => {
          _vm.onFormChanged();
          _vm.user.organization.id = null;
          _vm.user.reseller.id = null;
        }},model:{value:(_vm.user.is_staff),callback:function ($$v) {_vm.$set(_vm.user, "is_staff", $$v)},expression:"user.is_staff"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("USERS.IS_STAFF"))+" "),_c('span',{staticClass:"check"},[_c('i',{staticClass:"far fa-check"})])])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.is_staff}})],1):_vm._e(),(_vm.user.organization ? _vm.user.organization.id : null)?_c('div',{staticClass:"form-wrapper full",on:{"change":() => {
        _vm.onFormChanged();
      }}},[_c('base-checkbox',{model:{value:(_vm.user.restrict_to_locations),callback:function ($$v) {_vm.$set(_vm.user, "restrict_to_locations", $$v)},expression:"user.restrict_to_locations"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("USERS.RESTRICT_TO_LOCATIONS"))+" "),_c('span',{staticClass:"check"},[_c('i',{staticClass:"far fa-check"})])])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.restrict_to_locations}})],1):_vm._e(),(
      !_vm.user.is_staff &&
      _vm.showReseller &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
    )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.user.reseller ? _vm.user.reseller.id : null,"filterable":true,"showAll":false,"allowNone":true},on:{"resellerChanged":(resellerId) => {
            _vm.user.reseller = { type: 'resellers', id: resellerId };
            _vm.user.organization = { type: 'organizations', id: null };
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}})],1):_vm._e(),(
      !_vm.user.is_staff &&
      _vm.showOrganization &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
    )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"filterReseller":_vm.user.reseller ? _vm.user.reseller.id : null,"organization":_vm.user.organization ? _vm.user.organization.id : null,"filterable":true,"showAll":false,"allowNone":true},on:{"organizationChanged":(organizationId) => {
            _vm.user.organization = { type: 'organizations', id: organizationId };
            _vm.user.roles[0].id = null;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(
      _vm.user.restrict_to_locations &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS)
    )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.user.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.user.organization ? _vm.user.organization.id : null},on:{"locationsChanged":(locations) => {
            _vm.user.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.FIRSTNAME')} (*)`,"placeholder":_vm.$t('COMMON.FIRSTNAME')},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.firstname}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LASTNAME')} (*)`,"placeholder":_vm.$t('COMMON.LASTNAME')},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.lastname}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`,"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('div',{staticClass:"row equal"},[_c('div',{staticClass:"equal-item type"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
                _vm.user.phone_type = type;
                _vm.onFormChanged();
              }},model:{value:(_vm.user.phone_type),callback:function ($$v) {_vm.$set(_vm.user, "phone_type", $$v)},expression:"user.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"equal-item phone"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')}`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.user.phone},on:{"phoneNumberChanged":(phone) => {
                _vm.user.phone = phone;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"equal-item extension"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"inputClasses":'extension-input'},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.user.phone_extension),callback:function ($$v) {_vm.$set(_vm.user, "phone_extension", $$v)},expression:"user.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)])]),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.user.other_phones},on:{"phoneNumbersChanged":(phoneNumbers) => {
            _vm.user.other_phones = phoneNumbers;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}})],1),_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ROLE')} (*)`,"placeholder":_vm.$t('COMMON.ROLE')}},[_c('role-selector',{attrs:{"role":_vm.user.roles.length ? _vm.user.roles[0].id : null,"filterable":true,"showAll":false,"filterReseller":_vm.user.reseller ? _vm.user.reseller.id : null,"filterOrganization":_vm.user.organization ? _vm.user.organization.id : null},on:{"roleChanged":(roleId) => {
            _vm.user.roles[0].id = roleId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.roles}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCALE')} (*)`,"placeholder":_vm.$t('COMMON.LOCALE')}},[_c('locale-selector',{attrs:{"locale":_vm.user.locale},on:{"localeChanged":(locale) => {
            _vm.user.locale = locale;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.locale}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"type":"password","name":"new_password","prepend-icon":"fa fa-key","placeholder":`${_vm.$t('COMMON.PASSWORD')} ${_vm.user.id ? '' : '(*)'}`,"label":`${_vm.$t('COMMON.PASSWORD')} ${_vm.user.id ? '' : '(*)'}`},on:{"change":() => {
          _vm.onFormChanged();
        },"focus":function($event){_vm.showPasswordHint = true},"blur":function($event){_vm.showPasswordHint = false}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),(_vm.showPasswordHint)?_c('div',{staticClass:"mb-4"},[_c('small',{staticClass:"text-muted font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("USERS.PASSWORD_COMPLEXITY"))+" ")])]):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.password}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"type":"password","name":"confirm_password","autocomplete":"on","prepend-icon":"fa fa-key","placeholder":`${_vm.$t('COMMON.CONFIRM_PASSWORD')} ${
        _vm.user.id ? '' : '(*)'
      }`,"label":`${_vm.$t('COMMON.CONFIRM_PASSWORD')} ${_vm.user.id ? '' : '(*)'}`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.password_confirmation}})],1),_c('div',{staticClass:"col-12 mt-2 mb-3",staticStyle:{"display":"none"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))])]),_c('billing-informations-form',{staticStyle:{"display":"none"},attrs:{"billingInformationsData":_vm.user,"formErrors":_vm.formErrors},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.user.id ? _vm.$t("USERS.EDIT_USER") : _vm.$t("USERS.ADD_USER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }