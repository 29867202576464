export const REPAIR_ITEM_STATUS_APPROVED = "APPROVED";
export const REPAIR_ITEM_STATUS_DENIED = "DENIED";
export const REPAIR_ITEM_STATUS_REVIEWED = "REVIEWED";

export const REPAIR_ITEM_CONDITION_TO_REPLACE = "TO_REPLACE";
export const REPAIR_ITEM_CONDITION_TO_REPAIR = "TO_REPAIR";
export const REPAIR_ITEM_CONDITION_TO_MONITOR = "TO_MONITOR";
export const REPAIR_ITEM_CONDITION_TO_VERIFY = "TO_VERIFY";
export const REPAIR_ITEM_CONDITION_TO_SCHEDULE = "TO_SCHEDULE";

export const repairItemStatusesOption = {
  REPAIR_ITEM_STATUS_APPROVED,
  REPAIR_ITEM_STATUS_DENIED,
  REPAIR_ITEM_STATUS_REVIEWED,
};

export const repairItemConditionsOption = {
  REPAIR_ITEM_CONDITION_TO_REPLACE,
  REPAIR_ITEM_CONDITION_TO_REPAIR,
  REPAIR_ITEM_CONDITION_TO_MONITOR,
  // REPAIR_ITEM_CONDITION_TO_VERIFY,
  REPAIR_ITEM_CONDITION_TO_SCHEDULE,
};

export const repairItemConditionsOptionsDisplay = {
  [REPAIR_ITEM_CONDITION_TO_REPLACE]: {
    iconName: "fa-times-circle",
    color: "red",
    value: REPAIR_ITEM_CONDITION_TO_REPLACE,
  },
  [REPAIR_ITEM_CONDITION_TO_REPAIR]: {
    iconName: "fa-times-circle",
    color: "red",
    value: REPAIR_ITEM_CONDITION_TO_REPAIR,
  },
  [REPAIR_ITEM_CONDITION_TO_MONITOR]: {
    iconName: "fa-exclamation-triangle",
    color: "orange",
    value: REPAIR_ITEM_CONDITION_TO_MONITOR,
  },
  // [REPAIR_ITEM_CONDITION_TO_VERIFY]: {
  //   iconName: "fa-exclamation-triangle",
  //   color: "orange",
  //   value: REPAIR_ITEM_CONDITION_TO_VERIFY,
  // },
  [REPAIR_ITEM_CONDITION_TO_SCHEDULE]: {
    iconName: "fa-exclamation-triangle",
    color: "orange",
    value: REPAIR_ITEM_CONDITION_TO_SCHEDULE,
  },
};
