import { mapGetters } from "vuex";
import {
  REPAIR_STATUS_CANCELED,
  REPAIR_STATUS_COMPLETED,
  REPAIR_STATUS_DRAFT,
  REPAIR_STATUS_IN_PROGRESS,
  REPAIR_STATUS_ASSIGNED,
  REPAIR_STATUS_PENDING,
} from "../constants/repairs";

export default {
  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  methods: {
    isAssigned(repair) {
      return repair.status == REPAIR_STATUS_ASSIGNED;
    },

    isInProgress(repair) {
      return repair.status == REPAIR_STATUS_IN_PROGRESS;
    },

    isRepairable(repair) {
      return (
        !!repair &&
        [REPAIR_STATUS_IN_PROGRESS, REPAIR_STATUS_ASSIGNED].includes(
          repair.status
        )
      );
    },

    repairIsEnd(repair) {
      return [REPAIR_STATUS_COMPLETED, REPAIR_STATUS_CANCELED].includes(
        repair.status
      );
    },

    amRepairing(repair) {
      return this.isInProgress(repair) && repair.startedBy.id == this.me.id;
    },

    repairAreEditable(repair) {
      return (
        this.isRepairable(repair) &&
        this.$currentUserCan(this.$permissions.PERM_START_REPAIRS)
      );
    },

    canStartRepair(repair) {
      return this.repairAreEditable(repair) && this.isAssigned(repair);
    },

    canEndRepair(repair) {
      return (
        this.repairAreEditable(repair) &&
        this.amRepairing(repair) &&
        this.isInProgress(repair)
      );
    },

    canTakeOverRepair(repair) {
      return (
        this.isInProgress(repair) &&
        !this.amRepairing(repair) &&
        this.repairAreEditable(repair) &&
        this.$currentUserCan(this.$permissions.PERM_TAKE_OVER_REPAIRS)
      );
    },

    canValidateRepair(repair) {
      return (
        !!repair &&
        !!repair.vehicle &&
        this.$currentUserCan(this.$permissions.PERM_VALIDATE_REPAIRS) &&
        repair.status == REPAIR_STATUS_DRAFT
      );
    },

    isAssignable(repair) {
      return (
        !!repair &&
        // !!repair.vehicle &&
        this.$currentUserCan(this.$permissions.PERM_ASSIGN_REPAIRS) &&
        [REPAIR_STATUS_ASSIGNED, REPAIR_STATUS_PENDING].includes(repair.status)
      );
    },

    canAssignRepair(repair) {
      return (
        !!repair &&
        // !!repair.vehicle &&
        this.$currentUserCan(this.$permissions.PERM_ASSIGN_REPAIRS)
      );
    },

    canEditRepair(repair) {
      return (
        !!repair &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_REPAIRS) &&
        !this.repairIsEnd(repair)
      );
    },

    canDeleteRepair(repair) {
      return (
        !!repair &&
        this.$currentUserCan(this.$permissions.PERM_DELETE_REPAIRS) &&
        repair.status == REPAIR_STATUS_DRAFT
      );
    },

    canCancelRepair(repair) {
      return (
        !!repair &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_REPAIRS) &&
        !this.isInProgress(repair) &&
        !this.repairIsEnd(repair)
      );
    },

    canViewRepair(repair) {
      return (
        this.$idExist(repair?.id) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_REPAIRS) &&
        repair.status != REPAIR_STATUS_DRAFT
      );
    },

    canCreateRepair() {
      return this.$currentUserCan(this.$permissions.PERM_CREATE_REPAIRS);
    },
  },
};
