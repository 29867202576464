export default {
  INSPECTION_FORMS_LIST: "Inspection Form List",
  ADD_INSPECTION_FORM: "Add Inspection Form",
  INSPECTION_FORM_ADDED: "Inspection Form Added",
  EDIT_INSPECTION_FORM: "Edit Inspection Form",
  INSPECTION_FORM_UPDATED: "Inspection Form Updated",
  DELETE_THIS_INSPECTION_FORM: "Delete this Inspection Form?",
  INSPECTION_FORM_DELETED: "Inspection Form Deleted",
  INSPECTION_FORM_TYPE_INSPECTION_FORMS: "Inspection Form",
  ADD_INSPECTION_FORM_SECTION: "Add Section",
  FORM: "Form",
  EDIT_INSPECTION_FORM_SECTION: "Edit Section",
  INSPECTION_FORM_SECTION_ADDED: "Section Added",
  UPDATE_INSPECTION_FORM_SECTION: "Update Section",
  DELETE_INSPECTION_FORM_SECTION: "Delete Section",
  INSPECTION_FORM_SECTION_UPDATED: "Section Updated",
  DELETE_THIS_INSPECTION_FORM_SECTION: "Delete this section?",
  INSPECTION_FORM_SECTION_DELETED: "Section Deleted",
  ADD_INSPECTION_FORM_SECTION_FIELD: "Add Field",
  FIELD_TYPE_SELECT_SINGLE: "Single Select",
  FIELD_TYPE_SELECT_MULTIPLE: "Multiple Select",
  FIELD_TYPE_NUMBER: "Number",
  FIELD_TYPE_TEXT: "Text",
  FIELD_TYPE_RANGE_SLIDER: "Range Slider",
  FIELD_TYPE_CHECK: "Checkbox",
  FIELD_TYPE_STATE_WIDGET: "State Widget",
  FIELD_TYPE_TIRE_STATE_WIDGET: "Tire State Widget",
  FIELD_TYPE_BATTERY_STATE_WIDGET: "Battery State Widget",
  CAN_UPLOAD_IMAGE: "Can upload image",
  CAN_ADD_COMMENT: "Can add comment",
  FIELD_ICON: "Icon/Image",
  FIELD_POINTS_THRESHOLD: "Options and points",
  FIELD_POINTS_THRESHOLD_OPTION: "Option",
  FIELD_POINTS_THRESHOLD_POINTS: "Points",
  DELETE_INSPECTION_FORM_SECTION_FIELD: "Delete Field",
  UPDATE_INSPECTION_FORM_SECTION_FIELD: "Update Field",
  FIELD_POINTS_INSTRUCTIONS_CHECK: "",
  FIELD_POINTS_INSTRUCTIONS_SELECT_SINGLE: "",
  FIELD_POINTS_INSTRUCTIONS_SELECT_MULTIPLE: "",
  FIELD_POINTS_INSTRUCTIONS_NUMBER:
    "Enter options in the format <min value>-<max value> example 1-10 means from 1 to 10 (1 and 10 included)",
  FIELD_POINTS_INSTRUCTIONS_TEXT: "",
  FIELD_POINTS_INSTRUCTIONS_RANGE_SLIDER:
    "Enter options in the format <min value>-<max value> example 1-10 means from 1 to 10 (1 and 10 included)",
  FIELD_POINTS_INSTRUCTIONS_STATE_WIDGET:
    "The state widget has 4 options: good, to replace, bad, not applicable, enter points for each option",
  FIELD_POINTS_INSTRUCTIONS_TIRE_STATE_WIDGET:
    "The tire state widget is a slider with a color",
  FIELD_POINTS_INSTRUCTIONS_BATTERY_STATE_WIDGET:
    "The battery state widget has 4 options: good, to replace, bad, not applicable, enter points for each option",
  ADD_INSPECTION_FORM_SECTION_FIELD: "Add Field",
  TOTAL_POINTS: "Total Points",
  MAX_POINTS: "Max Points",
  SECTIONS_COUNT: "Number of Sections",
  FIELDS_COUNT: "Number of Fields",
  VIEW_INSPECTION_FORM: "View Inspection Form",
  DUPLICATE_THIS_INSPECTION_FORM: "Duplicate this Inspection Form?",
  DUPLICATE: "Duplicate",
  NEW_INSPECTION_FORM_SECTION: "New Section",
  EDIT_INSPECTION_FORM_SECTION: "Edit Section",
  NEW_INSPECTION_FORM_SECTION_FIELD: "New Field",
  EDIT_INSPECTION_FORM_SECTION_FIELD: "Edit Field",
  OPTION: "Option",
  TYPE: "Type",
  EXCERPT: "Description",
  ADD_INSPECTION_FORM_SUB_SECTION: "Add Sub Section",
  FACTOR: "Factor",
  FACTOR_ABBREV: "Coeff",
  ENABLE_NA: "Enable N/A",
  SYNC: "Sync",
  SYNC_PREVIEW: "Sync Preview",
  FIELDS_CREATED: "Created",
  FIELDS_UPDATED: "Updated",
  FIELDS_DELETED: "Deleted",
  FIELDS_ERRORS: "Errors",
  SYNC_RESULTS: "Sync Results",
  RUN_SYNCHRONISATION: "Run Synchronization",
  VIEW_DEMO: "View Demo",
  IMPAIRS_PROPER_FUNCTIONNING: "Impairs proper functioning",
  MAJOR: "Major",
  MEAN_REPAIR_VALUE: "Mean repair value",
  VEHICLE_PRICE: "Vehicle price",
  GLOBAL_POINTS: "Global Points",
  REPAIR_AMOUNT: "Estimated Repair Amount",
  SET_ALL_GOOD: "Set all to good",
  SET_ALL_WARNING: "Set all to warning",
  SET_ALL_BAD: "Set all to bad",
  FINAL_SCORE: "Final Score",
  INSPECTION_FORM_MULTIPLIERS: "Multipliers",
  ADD_INSPECTION_FORM_MULTIPLIER: "Add Multiplier",
  NEW_INSPECTION_FORM_MULTIPLIER: "New Multiplier",
  MULTIPLIER_TYPE: "Type",
  MULTIPLIER_TYPE_POINTS: "Points",
  MULTIPLIER_TYPE_REPAIR_VALUE: "Repair Value",
  MULTIPLIER_FIELD_MAKE: "Make",
  MULTIPLIER_FIELD_MODEL_YEAR: "Model Year",
  MULTIPLIER_FIELD_ODOMETER: "Odometer",
  MULTIPLIER_FIELD: "Field",
  MULTIPLIER_OPERATION: "Operation",
  MULTIPLIER_OPERATION_EQUALS: "Equals",
  MULTIPLIER_OPERATION_GREATER_THAN: "Greater than",
  MULTIPLIER_OPERATION_LESS_THAN: "Less than",
  MULTIPLIER_OPERATION_CONTAINS: "Contains",
  MULTIPLIER_VALUE: "Value",
  MULTIPLIER_MULTIPLIER: "Multiplier",
  INSPECTION_FORM_MULTIPLIER_ADDED: "Multiplier added",
  RETURN_TO_INSPECTION_FORM: "Return to Inspection Form",
  MULTIPLIERS: "Multipliers",
  IS_COPY: "Reference",
  COPIED_FIELD: "Reference Field",
  ALL_FIELDS: "All Fields",
  COPY_OF_FIELD: "Copy of Field",
  INSPECTION_FORM_NOTES: "Notes",
  ADD_INSPECTION_FORM_NOTE: "Add Note",
  NEW_INSPECTION_FORM_NOTE: "New Note",
  INSPECTION_FORM_NOTE_MIN_VALUE: "Minimum Value",
  INSPECTION_FORM_NOTE_MAX_VALUE: "Maximum Value",
  INSPECTION_FORM_NOTE: "Note",
  DELETE_THIS_INSPECTION_FORM_NOTE: "Delete this note?",
  INSPECTION_FORM_NOTE_ADDED: "Note added",
  INSPECTION_FORM_NOTE_UPDATED: "Note updated",
  INSPECTION_FORM_NOTE_DELETED: "Note deleted",
  RETURN_TO_INSPECTION_FORM_NOTES: "Return to Inspection Form Notes",
  NO_VEHICLE_FOUND_FOR_VIN: "No vehicle found for this VIN",
  MULTIPLIER_FIELD_MODEL: "Model",
  MULTIPLIER_FIELD_YEAR: "Year",
  MULTIPLIER_FIELD_STYLE: "Style",
  EDIT_INSPECTION_FORM_MULTIPLIER: "Edit Multiplier",
  UPDATE_INSPECTION_FORM_MULTIPLIER: "Update Multiplier",
  ADJUSTED_FINAL_SCORE: "Adjusted Final Score",
  NO_PRICE_FOUND_FOR_VEHICLE: "No price found for this vehicle",
  INSPECTION_FORM_REMINDER_ORDER: "Order",
  ADD_INSPECTION_FORM_REMINDER: "Add Reminder",
  INSPECTION_FORM_REMINDER_ADDED: "Reminder added",
  INSPECTION_FORM_REMINDER_UPDATED: "Reminder updated",
  DELETE_THIS_INSPECTION_FORM_REMINDER: "Delete this reminder?",
  INSPECTION_FORM_REMINDER_DELETED: "Reminder deleted",
  INSPECTION_FORM_REMINDERS: "Reminders",
  LINE: "Line",
  ERROR: "Error",
  FIELDS_UNTOUCHED: "Untouched fields",
  CONDITIONAL_FIELD: "Conditional Field",
  CONDITION_SHOW: "Show/Hide",
  SHOW: "Show",
  HIDE: "Hide",
  CONDITION_RELATION: "Relation",
  RELATION_AND: "And",
  RELATION_OR: "Or",
  FIELD: "Field",
  OPERATOR: "Operator",
  VALUE: "Value",
  OPERATOR_EQUALS: "Equals",
  OPERATOR_DIFFERENT: "Different from",
  OPERATOR_SUPERIOR: "Superior to",
  OPERATOR_INFERIOR: "Inferior to",
  FIELD_POINTS_INSTRUCTIONS_PAD_THICKNESS_WIDGET: "Pad thickness widget",
  FIELD_POINTS_INSTRUCTIONS_DRUM_THICKNESS_WIDGET: "Drum thickness widget",
  FUEL_TYPES: "Fuel Types",
};
