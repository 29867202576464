import store from "../store";
import moment from "moment";
import { i18n } from "./i18n";

const formatDate = {
  install: (Vue) => {
    Vue.prototype.$formatDate = (value, format = "LLL", isUTC = false) => {
      const me = store.getters["profile/me"];
      const momentDate = moment(value);
      if (isUTC) {
        momentDate.utc();
      }
      return momentDate.locale(i18n.locale).format(format);
    };
  },
};

export default formatDate;
