<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header" v-if="addSection">
      <div class="search">
        <div class="search-add">
          <base-button
            class="add"
            icon
            size="sm"
            @click="addRepair"
            v-if="canCreateRepair()"
          >
            <span class="btn-inner--icon">
              <i class="fa-regular fa-circle-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("REPAIRS.ADD_REPAIR") }}
            </span>
          </base-button>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>
        <div>
          <base-button
            class="notification"
            type="info"
            icon
            size="sm"
            title="corbeille"
            :class="{ active: onlyTrashed }"
            @click="onlyTrashed = !onlyTrashed"
          >
            <span class="btn-inner--icon">
              <i class="fa-light fa-trash"></i>
            </span>
          </base-button>
        </div>

        <!--<notification-subscription
          :objectType="'repairs'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />-->
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            class="advanced-search-item"
          >
            <organization-selector
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>
          <div class="advanced-search-item">
            <el-select class="select-primary" v-model="selectedStatus">
              <el-option
                class="select-primary"
                key="0"
                :label="$t('COMMON.STATUS') + '...'"
                :value="null"
              />
              <el-option
                v-for="value in repairStatusesOption"
                class="select-primary"
                :key="value"
                :label="$t(`REPAIRS.REPAIR_STATUS_${value}`)"
                :value="value"
              />
            </el-select>
          </div>
          <div class="advanced-search-item date">
            <date-range-selector
              @onDateRangeChanged="
                (dates) => {
                  selectedDateRange = dates;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('REQUESTS.ASSIGNED_TO')"
              @userChanged="(userId) => (selectedAssignedTo = userId)"
              :filter-permission="$permissions.PERM_START_REPAIRS"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('REPAIRS.COMPLETED_BY')"
              @userChanged="(userId) => (selectedCompletedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCreator &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
              false
            "
          >
            <user-selector
              :placeholder="$t('REPAIRS.CREATOR')"
              @userChanged="(userId) => (selectedCreator = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCustomer &&
              $currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS) &&
              false
            "
          >
            <customer-selector
              :disabled="false"
              @customerChanged="(customerId) => (selectedCustomer = customerId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <years-selector
              :filterable="true"
              :value="selectedVehicleModelYear"
              allow-none
              @yearsChanged="
                (year) => {
                  selectedVehicleModelYear = year;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <make-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :placeholder="$t('VEHICLES.MAKE')"
              :value="selectedVehicleMake"
              @makeChanged="
                (make) => {
                  selectedVehicleMake = make;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <model-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :make="selectedVehicleMake"
              :value="selectedVehicleModel"
              @modelChanged="
                (model) => {
                  selectedVehicleModel = model;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
              false
            "
          >
            <serie-selector
              allow-none
              :year="selectedVehicleModelYear"
              :model="selectedVehicleModel"
              :filterable="true"
              :value="selectedVehicleSeries"
              @serieChanged="
                (serie) => {
                  selectedVehicleSeries = serie;
                }
              "
            />
          </div>

          <div class="advanced-search-item d-inline-flex">
            <base-input
              :placeholder="$t('COMMON.ODOMETER_FROM')"
              v-model.number="odometer.from"
              type="number"
            />
            <base-input
              :placeholder="$t('COMMON.ODOMETER_TO')"
              v-model.number="odometer.to"
              type="number"
            />
            <!-- <el-select
              v-model="odometer_unit"
              :placeholder="$t('VEHICLES.ODOMETER_UNIT')"
            >
              <el-option value="km" :label="$t('ODOMETERS.KM')" />
              <el-option value="miles" :label="$t('ODOMETERS.MILES')" />
            </el-select> -->
          </div>
          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) && false
            "
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATION')}`">
              <location-selector
                @locationChanged="(location) => (selectedLocation = location)"
              />
            </base-input>
          </div>
          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTIONS) && false
            "
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.INSPECTION')}`">
              <inspection-selector
                @inspectionChanged="
                  (inspection) => (selectedInspection = inspection)
                "
              />
            </base-input>
          </div>
          <!-- <div
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTIMATIONS)"
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.ESTIMATE')}`">
              <estimate-selector
                @estimateChanged="
                  (estimate) => (selectedEstimate = estimate)
                "
              />
            </base-input>
          </div> -->
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text"> {{ $t("COMMON.UPDATE") }} </span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>

    <repair-table
      v-if="showInProgresses"
      :columns="columns"
      :filter-status="REPAIR_STATUS_IN_PROGRESS"
      :filterVehicle="filterVehicle"
      :header="$t('COMMON.IN_PROGRESS')"
      :pagination="pagination"
      :query="query"
      :selectedCreator="selectedCreator"
      :selectedCustomer="selectedCustomer"
      :selectedAssignedTo="selectedAssignedTo"
      :selectedDateRange="selectedDateRange"
      :selectedInspection="selectedInspection"
      :selectedOrganization="selectedOrganization"
      :selectedVehicleMake="selectedVehicleMake"
      :selectedVehicleModel="selectedVehicleModel"
      :selectedVehicleModelYear="selectedVehicleModelYear"
      :selectedVehicleSeries="selectedVehicleSeries"
      :filter-odometer="odometer"
      :show-pagination="false"
      :only-trashed="onlyTrashed"
      @onDeleteRepair="deleteRepair"
      @onViewRepair="viewRepair"
      ref="inProgressTable"
    />

    <repair-table
      :columns="columns"
      :exclude-status="showAll ? null : REPAIR_STATUS_IN_PROGRESS"
      :filter-status="showAll ? [] : selectedStatus"
      :filterVehicle="filterVehicle"
      :pagination="pagination"
      :query="query"
      :selectedCompletedBy="selectedCompletedBy"
      :selectedCreator="selectedCreator"
      :selectedAssignedTo="selectedAssignedTo"
      :selectedCustomer="selectedCustomer"
      :selectedDateRange="selectedDateRange"
      :selectedInspection="selectedInspection"
      :selectedOrganization="selectedOrganization"
      :selectedVehicleMake="selectedVehicleMake"
      :selectedVehicleModel="selectedVehicleModel"
      :selectedVehicleModelYear="selectedVehicleModelYear"
      :selectedVehicleSeries="selectedVehicleSeries"
      :filter-odometer="odometer"
      :show-tabs="showTabs"
      :only-trashed="onlyTrashed"
      @onDeleteRepair="deleteRepair"
      @onViewRepair="viewRepair"
      :show-pagination="showPagination"
      ref="repairTable"
    />
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { BasePagination } from "@/components";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import RepairStatusBadge from "./RepairStatusBadge.vue";
import InspectionSelector from "@/components/InspectionSelector.vue";
// import EstimateSelector from "@/components/EstimateSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import YearsSelector from "@/components/YearsSelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import CopyElement from "@/components/CopyElement.vue";
import RepairTable from "./RepairTable.vue";
import { REPAIR_STATUS_IN_PROGRESS } from "@/constants/repairs";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { repairStatusesOption } from "@/constants/repairs";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";

export default {
  name: "repair-list-table",

  components: {
    BasePagination,
    NotificationSubscription,
    DateRangeSelector,
    CustomerSelector,
    OrganizationSelector,
    LocationSelector,
    UserSelector,
    RepairStatusBadge,
    InspectionSelector,
    YearSelector,
    YearsSelector,
    MakeSelector,
    ModelSelector,
    SerieSelector,
    CopyElement,
    RepairTable,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, repairPermissionsMixin],

  props: {
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    showInProgresses: {
      type: Boolean,
      default: false,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array | null,
      default: () => [
        "vehicle",
        "vin",
        "customer",
        "model",
        "status",
        "mechanic",
        "updated_at",
        "days",
      ],
    },
    addSection: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      query: null,
      showAdvancedFilter: false,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      REPAIR_STATUS_IN_PROGRESS,
      repairStatusesOption,
      selectedVehicleModelYear: null,
      selectedVehicleMake: null,
      selectedVehicleModel: null,
      selectedVehicleSeries: null,
      filterOrganization: null,
      filterCustomer: null,
      filterCreator: null,
      selectedStatus: null,
      selectedDateRange: null,
      selectedAssignedTo: null,
      selectedCompletedBy: null,
      selectedCreator: null,
      selectedCustomer: null,
      selectedOrganization: null,
      selectedInspection: null,
      onlyTrashed: false,
      odometer: { from: null, to: null },
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getList() {},
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async deleteRepair(repair) {
      if (!this.canDeleteRepair(repair)) {
        return;
      }
      this.$emit("onDeleteRepair", repair);
    },

    viewRepair(repair) {
      if (!repair.assigned_at) {
        if (this.canEditRepair(repair)) {
          this.$emit("onEditRepair", repair);
          return;
        }
      }

      this.$emit("onViewRepair", repair);
    },

    editRepair(repair) {
      if (!this.canEditRepair(repair)) {
        return;
      }
      this.$emit("onEditRepair", repair);
    },

    addRepair() {
      this.$emit("onAddRepair");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    refreshTable() {
      this.$refs.repairTable.getList();
      this.$refs.repairTable.getCounters();
      this.$refs.inProgressTable.getList();
    },
  },
};
</script>
