<template>
  <div class="inspection-details-content-item">
    <div class="inspection-details-content-item-header">
      <div class="left">
        <h5>{{ subSectionData.name }}</h5>
      </div>
    </div>
    <div class="inspection-details-content-item-list">
      <div class="item" v-for="field in subSectionData.fields" :key="field.id">
        <inspection-view-inspection-section-sub-section-field-view
          :fieldData="field"
          :disabled="disabled"
          :inspection="inspection"
          :settingLoading="settingLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import {
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
} from "@/constants/inspectionForms";
import InspectionViewInspectionSectionSubSectionFieldView from "./InspectionViewInspectionSectionSubSectionFieldView.vue";

export default {
  name: "inspection-view-inspection-section-sub-section-view",

  components: {
    [Button.name]: Button,
    InspectionViewInspectionSectionSubSectionFieldView,
  },

  props: ["subSectionData", "disabled", "inspection", "settingLoading"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
  },

  mounted() {},

  watch: {},
};
</script>
